import React from 'react';

// style
import common from "scss/common.module.scss";

//img
import mv from "resources/img/mainimage.jpg";

// component
import Article from "components/cms/DisplayArticle";

const Profile = (props) => {
	const { type } = props;

	return (
		<>
			<div
				className={common['mv']}
				style={{
					backgroundImage: `url(${mv})`
				}}
			>
			</div>
			<div
				className={`${common['main-wrapper']}`}
			>
				<section
					className={common['common-wrapper']}
				>
					<Article
						type={type}
					/>
				</section>
			</div>
		</>
	);
}

export default Profile;
