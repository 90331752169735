import React, { useState } from 'react';
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import { Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";

// style
import common from "scss/common.module.scss";
import styles from 'scss/account.module.scss';

// component
import Loading from "components/loading/loading";
import AccountNav from './section/nav';
import Email from './section/email';
import Profile from './section/profile';
import UserPassword from './section/password';
import OrderHistory from 'components/pages/order/history';

//img
import mv from "resources/img/mainimage.jpg";

const Account = (prop) => {
	const { type } = prop;
	const location = useLocation();
	const [currentMenu, setCurrentMenu] = useState('user');
	const [user] = useRest("User:get");

	const buildContent = () => {
		switch(type) {
		case "account":
			return <Email />
		case "account-profile":
			return <Profile />
		case "account-password":
			return <UserPassword />
		case "account-history":
			return <OrderHistory />
		default: 
			break;
		}
	}

	if (user === null) {
		return (
			<>
				<div
					className={`${common['main-wrapper']}`}
				>
					<Loading />
				</div>
			</>
		);
	}
	else if (!user.data) {
		return <Redirect to={`/login?return_to=${location.pathname}`}/>
	}
	else {
		return (
			<>
				<div
					className={common['mv']}
					style={{
						backgroundImage: `url(${mv})`
					}}
				>
				</div>
				<div
					className={`${common['main-wrapper']}`}
				>
					<section
						className={`${common['common-wrapper']} ${styles['account-wrapper']}`}
					>
						<AccountNav
							currentMenu={currentMenu}
							setCurrentMenu={setCurrentMenu}
							type={type}
						/>
						<div
							className={styles['account-body']}
						>
							{buildContent()}
						</div>
					</section>
				</div>
			</>
		);
	}
}

export default Account;