import React from 'react';
import {Get} from "@karpeleslab/klbfw";
import { useTranslation } from 'react-i18next';

const Html = () => {
	const { t } = useTranslation();

	return (
		<div id="mail-html">
			<div style={{
				color: "#666",
				marginTop: "-6px"
			}}>
				<table cellSpacing="0" cellPadding="5" border="0" align="center" width="600" style={{
					lineHeight: "1.4rem",
					color:"#989d9f"
				}}>
					<tr>
						<td style={{
							textAlign: "center",
							padding: "20px"
						}}>
							<img src="https://www.rapobake.com/img_mail_logo.png" width="200px" alt="rapobake Shop"/>
						</td>
					</tr>
					<tr>
						<td>
							<hr size="2" color="#412d6b" />
						</td>
					</tr>
					<tr>
						<td>
							{t('mail_user_name', {name:`${Get('_order').Billing_User_Location.Last_Name} ${Get('_order').Billing_User_Location.First_Name}`})}<br />
							<br />
							{t('mail_shipping_complete')}
						</td>
					</tr>
					<tr>
						<td>
							<h3><font color="#33212f">{t('mail_contact')}</font></h3>
							<p><a href="https://rapobake.com/contact/">お問い合わせはこちらから</a></p>
						</td>
					</tr>
					<tr>
						<td>
							<hr size="2" color="#412d6b"/>
						</td>
					</tr>
					<tr>
						<td>
							<p>
								<a href="https://rapobake.com/">https://rapobake.com/</a>
							</p>
						</td>
					</tr>
				</table>
			</div>
		</div>
	);
};

export default Html;