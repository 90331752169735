import React from 'react';
import { useRest } from '@karpeleslab/react-klbfw-hooks';


// style
import common from "scss/common.module.scss";

//img

// component
import Title from "components/title/title";
import Helmet from 'components/helmet/helmet';
import Loading from "components/loading/loading";
import ProfileCard from "./profileCard";
import LowerVisual from 'components/mv/lowerVisual';

const Profile = () => {
	const MAIN_IMG_VARIATION = 'format=png&strip&scale_crop=460x460';

	const imgVariation = [
		MAIN_IMG_VARIATION,
	];

	const [member] = useRest("Music/Label/Artist/@artist/Member", {"image_variation": imgVariation});
	
	return (
		<>
			<Helmet>
				<title>PROFILE | ラップオバケ OFFICIAL WEB SITE</title>
				<meta property="og:title" content="PROFILE | ラップオバケ OFFICIAL WEB SITE" />
				<meta name="twitter:title" content="PROFILE | ラップオバケ OFFICIAL WEB SITE" />
			</Helmet>
			<LowerVisual />
			<div
				className={`${common['main-wrapper']}`}
			>
				<section
					className={common['common-wrapper']}
				>
					<Title
						title="PROFILE"
					/>
					{member === null ? <Loading /> : member.data.map((val, index) => {
						return <ProfileCard key={index} variation={MAIN_IMG_VARIATION} memberData={val} />
					})}
				</section>
			</div>
		</>
	);
}

export default Profile;
