import React from 'react';
import {Get} from '@karpeleslab/klbfw';
import { useTranslation } from 'react-i18next';

const Text = () => {
	const { t } = useTranslation();
	return (
		<div id="mail-text">
			{t('mail_user_name', {name:Get('_user').Profile.Display_Name} )}

			ーーーーーーーーーーーーーーーーーーー■-□ { t('mail_name') } □-■ーーーーーーーーーーーーーーーーーーー
			{t('mail_registration_completed_text_01')}
			{t('mail_registration_completed_text_02')}

			{t('mail_common_text_03')}

			{t('mail_common_text_01')}
			{t('mail_common_text_02')}
		</div>
	);
};

export default Text;