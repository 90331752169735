import React from 'react';
import classNames from 'classnames';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faAddressCard, faLock, faHistory } from '@fortawesome/free-solid-svg-icons';

// style
import styles from 'scss/account.module.scss';

const AccountNav = (prop) => {
	const { type } = prop;
	const menuArray = [
		{
			val: "メールアドレス",
			path: "/account",
			current: "account",
			icon: <FontAwesomeIcon icon={faEnvelope} />
		},
		{
			val: "ユーザー情報",
			path: "/account/profile",
			current: "account-profile",
			icon: <FontAwesomeIcon icon={faAddressCard} />
		},
		// {
		// 	val: "支払情報",
		// 	path: "/account",
		// 	current: "account-payment",
		// 	icon: <FontAwesomeIcon icon={faDollarSign} />
		// },
		{
			val: "パスワード",
			path: "/account/password",
			current: "account-password",
			icon: <FontAwesomeIcon icon={faLock} />
		},
		{
			val: "購入履歴",
			path: "/account/order-history",
			current: "account-history",
			icon: <FontAwesomeIcon icon={faHistory} />
		}
	]

	return (
		<>
			<div
				className={styles['account-nav-wrapper']}
			>
				<ul
					className={
						styles['account-nav']
					}
				>
					{ menuArray.map((item, key) => {
						return (
							<li
								className={
									styles['account-nav-item']
								}
								key={key}
							>
								<Link
									to={item.path}
									className={classNames(styles['account-nav-item-inner'], {
										[styles['account-nav-item-inner-active']]: type === item.current
									})}
								>
									<span
										className={styles['account-nav-item-inner-icon']}
									>
										{item.icon}
									</span>
									<span
										className={styles['account-nav-item-inner-text']}
									>
										{item.val}
									</span>
								</Link>
							</li>
						)
					}) }
				</ul>
			</div>
		</>
	);
}

export default AccountNav;