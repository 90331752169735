import React from "react";
import { Link } from "react-router-dom";
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// style
import style from "scss/paging.module.scss";

export default function Paging({page, list, path, year = ""}) {

	const buildPageNum = () => {
		const pageNum = parseInt(page);

		var pageNumList = [];
		for (let i = 1; i <= list.paging.page_max; i++) {
			if (pageNum === 1) {
				if (pageNum + 4 > i) {
					pageNumList.push(<li key={i} className={style['paging-list-item']}><Link to={`/${path}${year}/page-${i}`} className={classNames({ [style['active']]: i === list.paging.page_no })}>{i}</Link></li>);
				}
			}
			else if (pageNum === list.paging.page_max-1 ) {
				if (pageNum - 3 <  i && pageNum + 2 > i) {
					pageNumList.push(<li key={i} className={style['paging-list-item']}><Link to={`/${path}${year}/page-${i}`} className={classNames({ [style['active']]: i === list.paging.page_no })}>{i}</Link></li>);
				}
			}
			else if (pageNum === list.paging.page_max ) {
				if (pageNum - 4 <  i) {
					pageNumList.push(<li key={i} className={style['paging-list-item']}><Link to={`/${path}${year}/page-${i}`} className={classNames({ [style['active']]: i === list.paging.page_no })}>{i}</Link></li>);
				}
			}
			else {
				if (pageNum - 2 <  i && pageNum + 3 > i) {
					pageNumList.push(<li key={i} className={style['paging-list-item']}><Link to={`/${path}${year}/page-${i}`} className={classNames({ [style['active']]: i === list.paging.page_no })}>{i}</Link></li>);
				}
			}
		}
		return pageNumList
	}

	const buildTo = (type) => {
		const pageNum = parseInt(page);

		if (type === 'prev') {
			if (pageNum === 1) {
				return (
					<span className={`${style['paging-btn']} ${style['paging-btn-disabled']}`}><FontAwesomeIcon icon={['fas', 'angle-left']} /></span>
				)
			} else {
				return (
					<Link to={`/${path}${year}/page-${pageNum - 1}`} className={`${style['paging-btn']}`}><FontAwesomeIcon icon={['fas', 'angle-left']} /></Link>
				);
			}
		}
		else {

			if (pageNum === list.paging.page_max) {
				return (
					<span className={`${style['paging-btn']} ${style['paging-btn-disabled']}`}><FontAwesomeIcon icon={['fas', 'angle-right']} /></span>
				)
			} else {
				return (
					<Link to={`/${path}${year}/page-${pageNum + 1}`} className={`${style['paging-btn']}`}><FontAwesomeIcon icon={['fas', 'angle-right']} /></Link>
				);
			}
		}
	}

	const buildPaging = () => {

		if (list === null) return false;

		if (list.data.length === 0) {
			return null
		}

		return (
			<div className={style['paging']}>
				{buildTo('prev')}
				<ol className={style['paging-list']}>
					{buildPageNum()}
				</ol>
				{buildTo('next')}
			</div>
		)
	}
	return (
		<>
			{buildPaging()}
		</>
	)
}
