import React from 'react';

// style
import styles from "scss/loading.module.scss";

// icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const OverLay = () => {
	return (
		<div className={styles['loading-overlay']}>
			<FontAwesomeIcon icon={faSpinner} />
		</div>
	);
};

export default OverLay;

