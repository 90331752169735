import React, { useEffect, useState } from 'react';
import { useRest, useRestRefresh } from '@karpeleslab/react-klbfw-hooks';
import { rest, getLocale } from "@karpeleslab/klbfw";
import { useParams, useHistory, Link, useLocation } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js';
import { ToastContainer, toast } from 'react-toastify';
import { Elements } from "@stripe/react-stripe-js";
import { useTranslation } from 'react-i18next';
import { Redirect } from "react-router-dom";
import moment from "moment";

// component
import Loading from "components/loading/loading";
import Cart from "components/cart/list_order";
import Stripe from "components/stripe/stripe";

// style
import common from "scss/common.module.scss";

const Order = () => {
	const slug = useParams();
	const [orderProcess] = useRest("Order/"+slug.order+":process");
	const [user] = useRest("User:get");
	const [paymentClass, setPeymentClass] = useState("");
	const [paymentStatus, setPaymentStatus] = useState(false);
	const cartRefresh = useRestRefresh("Catalog/Cart/@");
	const orderRefresh = useRestRefresh("Order/"+slug.order+":process");
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();


	useEffect(() => {
		if (orderProcess !== null) {
			paymentClassHandler(orderProcess)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderProcess])


	const bankTransferHandler = (data) => {
		const paymentStatus = data.data.order.Status;

		switch (paymentStatus) {
		case 'pending-initiated':
			setPaymentStatus(true);
			break;
		default:
			setPaymentStatus(false);
			break;
		}

	}

	const stripeHandler = (data) => {
		const paymentStatus = data.data.order.Status;

		switch (paymentStatus) {
		case 'completed':
			setPaymentStatus(true);
			break;
		default:
			setPaymentStatus(false);
			break;
		}
	}

	const paymentClassHandler = (data) => {
		const paymentClass = data.data.order.Payment_Class;
		setPeymentClass(paymentClass);
	
		switch (paymentClass) {
		case "AozoraNet":
			bankTransferHandler(data);
			break;
		default:
			stripeHandler(data);
			break;
		}
	}


	const submitForm = (token) => {
		setLoading(true);
		rest(`Order/${orderProcess.data.order.Order__}:process`, 'POST', 
			{
				method: orderProcess.data.methods.Stripe.method,
				session: orderProcess.data.methods.Stripe.session,
				cc_token:token.token.id
			}
		)
			.then((data) => {
				setLoading(false);
				cartRefresh();
				history.push('/thankyou');
			})
			.catch(
				(data) => {
					setLoading(false);
					toast.error(data.message, {
						position: "top-center",
						autoClose: 3000,
					});
				}
			);
	}

	const submitFurikomi = () => {
		setLoading(true);
		rest(`Order/${orderProcess.data.order.Order__}:process`, 'POST',
			{
				method: orderProcess.data.methods.AozoraNet.method,
				session: orderProcess.data.methods.AozoraNet.session,
			}
		)
			.then((data) => {
				orderRefresh();
				cartRefresh();
				paymentClassHandler(data);
				setLoading(false);
				// history.push('/thankyou');
			})
			.catch((data) => {
				setLoading(false);
				toast.error(data.message, {
					position: "top-center",
					autoClose: 3000,
				});
			});
	}

	const stripeError = (message) => {
		toast.error(message, {
			position: "top-center",
			autoClose: 3000,
		});
	}

	const buildStripeElement = () => {

		let stripePromise = null;

		if ( !orderProcess.data.methods.Stripe.fields.cc_token.attributes.options.stripe_account ) {
			stripePromise = loadStripe(orderProcess.data.methods.Stripe.fields.cc_token.attributes.key);
			
		} else {
			stripePromise = loadStripe(orderProcess.data.methods.Stripe.fields.cc_token.attributes.key, { stripeAccount: orderProcess.data.methods.Stripe.fields.cc_token.attributes.options.stripe_account});
		}
		
		return (
			<Elements stripe={stripePromise}>
				<Stripe
					submitForm={submitForm}
					stripeError={stripeError}
				/>
			</Elements>
		)
	}

	const buildStripe = () => {
		if (!orderProcess.data.methods.Stripe || paymentStatus) {
			return null;
		}
		else {
			return (
				<>
					<div
						className={`${common['sub-title-wrapper']} ${common['sub-title-wrapper-top']}`}
					>
						<h3
							className={common['sub-title']}>
							{t('order_card_btn')}
						</h3>
						<p className={common['sub-title-info']}>{t('card_payment_terms_lbl')}</p>
					</div>
					{buildStripeElement()}
				</>
			)
		}
	}

	const buldExpires = () => {
		const expires = orderProcess.data.order.Meta.AozoraNet_Expires;
		const currentLang = getLocale();

		switch (currentLang) {
		case "ja-JP":
			return moment(expires).format("YYYY/MM/DD HH:mm:ss");
		default:
			return moment(expires).format("MM/DD/YYYY HH:mm:ss");
		}

	}

	const buildBackBtn = () => {
		const locationSearch = location.search;

		switch(locationSearch) {
		case "?type=history":
			return (
				<button
					onClick={() => {history.goBack()}}
				>
					{t('to_prev')}
				</button>
			)
		default:
			return (
				<Link
					to="/"
				>
					{t('to_top')}
				</Link>
			)
		}
	}

	const buildTransferInfo = () => {
		return (
			<>
				<div
					className={common['sub-title-wrapper']}
				>
					<h3
						className={common['sub-title']}>
						{t('order_furikomi_info_title')}
					</h3>
					<p className={common['sub-title-info']}>{t('order_furikomi_info_text')}</p>
				</div>
				
				<div className={common['wrapper']} style={{ whiteSpace: 'pre-line' }}>{t('order_furikomi_aozoranet', {order: orderProcess.data.order, expires: buldExpires()} )}</div>
				<div className={common['btn-warapper']}>
					{buildBackBtn()}
				</div>
			</>
		)
	}


	const buildFurikomi = () => {
		if (!orderProcess.data.methods.AozoraNet) {
			return null
		} else if (paymentClass === "AozoraNet" && paymentStatus) {
			return buildTransferInfo()
		}
		else {
			return (
				<>
					<div
						className={common['sub-title-wrapper']}
					>
						<h3
							className={common['sub-title']}>
							{t('order_furikomi_btn')}
						</h3>
						<p className={common['sub-title-info']}>{t('order_furikomi_lbl')}</p>
					</div>
					<div className={common['btn-warapper']}>
						<button
							className={common['btn-submit']}
							type="button"
							onClick={() => { submitFurikomi() }}
						>{t('order_furikomi_btn')}</button>
					</div>
				</>
			)
		}
	}

	const buildPayment = () => {
		if (orderProcess.data.order.Status !== 'completed') {
			return (
				<>
					{buildFurikomi()}
					{buildStripe()}
				</>
			)
		}
	}

	const buildTitle = () => {
		if (paymentClass === "AozoraNet" && paymentStatus) {
			return t('order_furikomi_process_comp')
		} else if (paymentClass === "Stripe" && paymentStatus) {
			return t('order_furikomi_process_comp')
		} else {
			return t('title_purchase')
		}
	}

	const buildOrderInfo = () => {
		const locationSearch = location.search;

		if (!paymentStatus || locationSearch !== '?type=history') return false;

		const invoiceNum = orderProcess.data.order.Invoice_Number;
		const orderCreatedDate = moment(parseInt(orderProcess.data.order.Created?.unixms)).format("YYYY-MM-DD HH:mm:ss");
		const invoiceDate = moment(parseInt(orderProcess.data.order.Invoice_Date?.unixms)).format("YYYY-MM-DD HH:mm:ss");

		return (
			<>
				<div className={common['wrapper']}>
					<dl
						className={common['order-info']}
					>
						<div>
							<dt>支払い完了番号</dt>
							<dd>{invoiceNum !== null ? invoiceNum : "-"}</dd>
						</div>
						<div>
							<dt>支払い完了日時</dt>
							<dd>{invoiceDate !== "Invalid date" ? invoiceDate : "-"}</dd>
						</div>
						<div>
							<dt>注文日時</dt>
							<dd>{orderCreatedDate !== "Invalid date" ? orderCreatedDate : "-"}</dd>
						</div>
						<div>
							<dt>ステータス</dt>
							<dd>{t(`order-status-${orderProcess.data.order.Status}`)}</dd>
						</div>
					</dl>
				</div>
			</>
		)
	}


	if (orderProcess === null || loading === true || user === null) {
		return (
			<>
				<div
					className={`${common['main-wrapper']}`}
				>
					<Loading />
				</div>
			</>
		);
	}

	if (!user.data) {
		return <Redirect to="/login?return_to=/checkout"/>
	}

	return (
		<>
			<div
				className={`${common['main-wrapper']}`}
			>
				<h2 className={common['title']}>{buildTitle()}</h2>
				<div
					className={common['sub-title-wrapper']}
				>
					<h3
						className={common['sub-title']}
					>{t('title_order')}</h3>
				</div>
				{buildOrderInfo()}
				<Cart
					removeItem={null}
					cart={orderProcess}
				/>
				{buildPayment()}
				<ToastContainer />
			</div>
		</>
	);
}

export default Order;
