import React from 'react';
import { useTranslation } from 'react-i18next';

// style
import styles from "scss/cart.module.scss";
import common from "scss/common.module.scss";

// component
import Loading from "components/loading/loading";

const Cart = (props) => {

	const {cart} = props;
	const { t } = useTranslation();


	const buildItem = () => {
		return cart.data.order.Items.map((item, idx) => {
			return (
				<dl key={idx}>
					<div>
						<dt>{t('cart_name')}</dt>
						<dd>{item.Catalog_Product['Basic.Name']}</dd>
					</div>
					<div>
						<dt>{t('cart_quantity')}</dt>
						<dd>{item.Quantity}</dd>
					</div>
					<div>
						<dt>{t('cart_price')}</dt>
						<dd>{item.Price_Vat.display}</dd>
					</div>
				</dl>
			)
		})
	}

	const buildAllPrice = () => {
		return (
			<dl className={styles['cart-total']}>
				<div>
					<dt>{t('cart_subtotal')}</dt>
					<dd>{cart.data.order.Total.display}</dd>
				</div>
				<div>
					<dt>{t('cart_tax')}</dt>
					<dd>{cart.data.order.Vat_Amount.display}</dd>
				</div>
				<div>
					<dt>{t('cart_total')}</dt>
					<dd>{cart.data.order.Total_Vat.display}</dd>
				</div>
			</dl>
		)
	}


	const buildCart = () => {
		
		if (cart === null) {
			return <Loading />
		}
		else if (cart.data.order.Items.length === 0) {
			return (
				<div
					className={styles['cart-nothing']}
				>
					{t('cart_no_item')}
				</div>
			)
		}
		else {
			return (
				<>
					<div className={styles['cart-item']}>
						{buildItem()}
					</div>
					{buildAllPrice()}
				</>
			)
		}
	}


	return (
		<div className={common['wrapper']}>
			{buildCart()}
		</div>
	);
}

export default Cart;