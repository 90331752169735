import React, { useState, useEffect } from 'react';
import { rest } from "@karpeleslab/klbfw";
import { Link, useParams } from "react-router-dom";
import { Get } from '@karpeleslab/klbfw';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// style
import common from "scss/common.module.scss";
import styles from 'scss/forgotten.module.scss';

//img
import mv from "resources/img/mainimage.jpg";

// component
import Title from "components/title/title";
import Helmet from 'components/helmet/helmet';
import Loading from "components/loading/loading";

const ForgottenPassword = () => {
	const [loading, setLoading] = useState(false);
	const { login } = useParams();
	const [form, setForm] = useState({
		new_password: null,
		new_password_confirm: null,
	});

	const [sent, setSent] = useState(false);
	const [submitDisable, setSubmitDisable] = useState(true);
	const [showError, setShoeError] = useState(false);

	const formChange = (event) => {
		const array = {}
		array[event.target.getAttribute('name')] = event.target.value;

		setForm({
			...form,
			...array
		});

	};

	const emailSubmit = (event) => {
		event.preventDefault();
		setLoading(true);

		const param = {
			login: login,
			key: Get('k'),
			p1: form.new_password,
			p2: form.new_password_confirm
		}

		// local check
		// setTimeout(() => {
		// 	setLoading(false);
		// 	// 	setSent(true);
		// 	toast.error('パスワード再設定に失敗しました。', {
		// 		position: "top-center",
		// 		autoClose: 3000,
		// 	});
		// }, 3000)

		rest("User:recover_password", "POST", param)
			.then(() => {
				setLoading(false);
				setSent(true);
			})
			.catch(() => {
				setLoading(false);
				toast.error('パスワード再設定に失敗しました。', {
					position: "top-center",
					autoClose: 3000,
				});
			})
	}

	useEffect(() => {

		if (form.new_password !== null && form.new_password_confirm !== null && form.new_password === form.new_password_confirm) {
			setSubmitDisable(false);
			setShoeError(false);
		}
		else if (form.new_password !== null && form.new_password_confirm !== null && form.new_password !== form.new_password_confirm) {
			setSubmitDisable(true);
			setShoeError(true);
		}
		else {
			setSubmitDisable(true);
		}		

	}, [form, setSubmitDisable]);

	const buildContent = () => {
		if (sent === false && loading === false) {
			return (
				<>
					<p>パスワードを再設定することで、今後ログインの際新たに設定したパスワードをつかえます。以前使っていただいてたパスワードほその後つかえなくなります。また、パスワードの再設定は２４時間に１回しかできませんので、ご注意ください。</p>
					<form onSubmit={(e) => {emailSubmit(e)}}>
						<div
							className={common['form-item']}
						>
							<input className={common['input']} placeholder="新しいパスワード" value={form.new_password === null ? '' : form.new_password} type="password" name="new_password" onChange={formChange} required/>
						</div>
						<div
							className={common['form-item']}
						>
							<input className={common['input']} placeholder="新しいパスワード（確認用）" value={form.new_password_confirm === null ? '' : form.new_password_confirm} type="password" name="new_password_confirm" onChange={formChange} required/>
						</div>

						{showError && <p>パスワードが一致していません。</p>}

						<div className={common['btn-warapper']}>
							<button className={common['btn-submit']} type="submit" disabled={submitDisable}>送信</button>
						</div>
					</form>
				</>
			)
		}
		else if (sent === false && loading === true) {
			return <Loading />
		}
		else {
			return (
				<>
					<p style={{textAlign:"center"}} >パスワード再設定が正常に完了しました。新しく設定されたパスワードでログインができます。</p>

					<div className={common['btn-warapper']}>
						<Link
							to="/login"
						>ログインする</Link>
					</div>
				</>
			)
		}
	}

	return (
		<>
			<Helmet>
				<title>パスワード再設定 | ラップオバケ OFFICIAL WEB SITE</title>
				<meta property="og:title" content="パスワード再設定 | ラップオバケ OFFICIAL WEB SITE" />
				<meta name="twitter:title" content="パスワード再設定 | ラップオバケ OFFICIAL WEB SITE" />
			</Helmet>
			<div
				className={common['mv']}
				style={{
					backgroundImage: `url(${mv})`
				}}
			>
			</div>
			<div
				className={`${common['main-wrapper']}`}
			>
				<section
					className={common['common-wrapper']}
				>
					<Title
						title="パスワード再設定"
					/>
					<div
						className={styles['forgotten-wrapper']}
					>
						{buildContent()}
					</div>
				</section>
			</div>
			<ToastContainer />
		</>
	);
}

export default ForgottenPassword;
