import React from 'react';
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import moment from "moment";
import { Link } from "react-router-dom";
import classNames from 'classnames';

// style
import common from "scss/common.module.scss";
import styles from "scss/index.module.scss";

//img

// component
import Loading from "components/loading/loading";
import Title from "components/title/title";

const News = () => {

	const [news] = useRest("Content/Cms/@news:search", { sort: "published:desc", query: {
		tag: "news"
	} });

	const oneWeekAgo = moment().subtract(7, "d");

	const buildNewsList = () => {
		if (news === null) {
			return <Loading />
		}
		else {
			return (
				<ul
					className={styles['newsList']}>
					{
						news.data.data.map((item, key) => {
							if (item.Slug === 'tokyo') {
								return null
							}
							else {
								return (
									<li
										key={key}
										className={classNames( {
											[styles['newsList-new']]: moment(item.Published.iso).isAfter(oneWeekAgo)
										})}
									>
										<Link
											to={`/news/${item.Slug}`}
										>{item.Title}</Link>
									</li>
								)
							}
						})
					}
				</ul>
				
			)
		}
	}

	return (
		<>
			<section
				className={common['common-wrapper']}
			>
				<Title
					title="NEWS"
				/>
				{buildNewsList()}
			</section>
		</>
	);
}

export default News;
