import React, { useEffect }  from 'react';
import { rest } from "@karpeleslab/klbfw";
import { useRest, useRestRefresh } from '@karpeleslab/react-klbfw-hooks';
import { Link, useLocation, useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import qs from 'qs';
import { useTranslation } from 'react-i18next';

// component
import Cart from "components/cart/list";
import Coupon from "components/coupon/coupon";

// style
import common from "scss/common.module.scss";

const Index = () => {
	const [cart] = useRest("Catalog/Cart/@");
	const cartRefresh = useRestRefresh("Catalog/Cart/@");
	const location = useLocation();
	const history = useHistory();
	const hash = qs.parse(location.search.split('?')[1]);
	const { t } = useTranslation();

	const addToCart = (hash) => {

		if ( hash === void 0 ) {
			return false;
		}

		const query = {
			request: hash,
		};

		rest('Catalog/Cart/@:process', 'POST', query)
			.then(
				() => {
					cartRefresh();
					history.push(`/redirect`);
				}
			)
			.catch(
				() => {
					toast.error(t('toast_item_add_error'), {
						position: "top-center",
						autoClose: 3000,
					});
				}
			)
	};


	const removeItem = (product, name) => {
		const query = {
			key: product,
			quantity:0
		};

		rest('Catalog/Cart/@:setQuantity', 'POST', query)
			.then(
				() => {
					toast.success(t('toast_item_remove', {name:name}), {
						position: "top-center",
						autoClose: 3000,
					});
					cartRefresh()
				}
			)
			.catch(
				() => {
					toast.error(t('toast_item_remove_error', {name:name}), {
						position: "top-center",
						autoClose: 3000,
					});
				}
			)
	}

	useEffect(() => {
		addToCart(hash.product);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	const buildBtn = () => {
		
		if (cart === null) {
			return null
		}
		else if (cart.data.products.length === 0) {
			return null
		}
		else {
			return (
				<div className={common['btn-warapper']}>
					<Link
						className={common['btn-glay']}
						to="/"
					>
						{t('to_goods')}
					</Link>

					<Link
						to="/checkout/"
					>
						{t('btn_purchase')}
					</Link>
				</div>
			)
		}
	}

	return (
		<>
			<div
				className={`${common['main-wrapper']}`}
			>
				<h2 className={common['title']}>マイカート</h2>
				<Cart
					removeItem={removeItem}
					cart={cart}
				/>
				<Coupon
					addToCart={addToCart}
					cart={cart}
				/>
				{buildBtn()}
				<ToastContainer />
			</div>
		</>
	);
}

export default Index;
