import React from 'react';
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import { getPrefix } from "@karpeleslab/klbfw";
import { useParams, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// style
import common from "scss/common.module.scss";
import styles from "scss/cms.module.scss";

//img
import mv from "resources/img/mainimage.jpg";

// component
import Loading from "components/loading/loading";
import Helmet from 'components/helmet/helmet';


const useQuery = () => new URLSearchParams(useLocation().search);

const Article = (props) => {
	const { type } = props;
	const { slug } = useParams();
	const query = useQuery();

	const MAIN_IMG_VARIATION = 'format=jpeg&strip&scale_crop=1000x625';

	const imgVariation = [
		MAIN_IMG_VARIATION,
	];

	const args = {
		slug: decodeURIComponent(slug),
		image_variation: imgVariation,
	};

	if (query.get("_preview")) {
		args._preview = query.get("_preview");
	}

	const [ article ] = useRest(`Planner/@planner/Schedule:loadBySlug`, args);

	const buildMainImg = () => {
		const driveItem = article.data.content_cms_entry_data.Drive_Item;

		if (driveItem === null || driveItem === void 0) {
			return null
		} else {
			return (
				<div
					className={common['cms-head-img']}
				>
					<img src={driveItem.Media_Image.Variation[MAIN_IMG_VARIATION]} alt={article.data.content_cms_entry_data.Title} />
				</div>
			)
		}
	}



	if (article === null || article === void 0 ) {
		return <Loading />
	}
	else {
		return (
			<>
				<Helmet>
					<title>{article.data.content_cms_entry_data.Title} | ラップオバケ OFFICIAL WEB SITE</title>
					{/* og */}
					<meta property="og:type" content="website" />
					<meta property="og:title" content={`${article.data.content_cms_entry_data.Title} | ラップオバケ OFFICIAL WEB SITE`} />
					{
						article.data.content_cms_entry_data.Short_Contents && <meta property="og:description" content={article.data.content_cms_entry_data.Short_Contents}/>
					}
					{
						article.data.content_cms_entry_data.Top_Drive_Item && <meta property="og:image" content={ article.data.content_cms_entry_data.Top_Drive_Item.View_Url } />
					}
					{
						article.data.content_cms_entry_data.Top_Drive_Item && <meta property="og:image:secure_url" content={ article.data.content_cms_entry_data.Top_Drive_Item.View_Url } />
					}
					<meta property="og:url" content={'https://rapobake.com' + getPrefix() + '/' + type + '/' + article.data.content_cms_entry_data.Slug}/>
					{/* twitter */}
					<meta name="twitter:title" content={`${article.data.content_cms_entry_data.Title} | ラップオバケ OFFICIAL WEB SITE`} />
					{
						article.data.content_cms_entry_data.Short_Contents && <meta name="twitter:description" content={article.data.content_cms_entry_data.Short_Contents}/>
					}
					{
						article.data.content_cms_entry_data.Top_Drive_Item && <meta name="twitter:image" content={article.data.content_cms_entry_data.Top_Drive_Item.View_Url} />
					}
				</Helmet>
				<div
					className={common['mv']}
					style={{
						backgroundImage: `url(${mv})`
					}}
				>
				</div>
				<div
					className={`${common['main-wrapper']}`}
				>
					<section
						className={common['common-wrapper']}
					>
						{ buildMainImg() }
						<h1
							className={styles['cms-title']}
						>{article.data.content_cms_entry_data.Title}</h1>
						<div className="cms-content" dangerouslySetInnerHTML={{__html: article.data.content_cms_entry_data.Contents}} /> 
					</section>
				</div>
				<ToastContainer />
			</>
		);
	}
};


export default Article;