import React, { useState } from 'react';
import { rest } from "@karpeleslab/klbfw";
import { useRest, useRestRefresh } from '@karpeleslab/react-klbfw-hooks';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from "react-router-dom";

// style
import common from "scss/common.module.scss";
import styles from "scss/goods.module.scss";

//img
import mv from "resources/img/img_mv.png";
import obake1 from "resources/img/img_obake1.png";
import obake2 from "resources/img/img_obake2.png";
import obake3 from "resources/img/img_obake3.png";
import obake4 from "resources/img/img_obake4.png";
import txt from "resources/img/img_text.png";
import noImg from "resources/img/img_noimage.png";

// component
import Loading from "components/loading/loading";
import Helmet from 'components/helmet/helmet';


const Goods = () => {
	const imageVariation = "format=png&strip&scale=400x400";
	const [products] =  useRest("Catalog/Product:search", {sort:'Basic.Priority:desc', results_per_page: 50, image_variation: imageVariation,});
	const [selectMax] = useState(5);
	const [selecLength, setSelectLength] = useState({});
	const cartRefresh = useRestRefresh("Catalog/Cart/@");
	const history = useHistory();

	const addToCart = (productId) => {

		let num = 1;

		if (selecLength[productId] !== void 0) {
			num = selecLength[productId]
		}

		const query = {
			product: productId,
			quantity: num
		};

		rest('Catalog/Cart/@:add', 'POST', query)
			.then(
				() => {
					cartRefresh();
					history.push("/cart");
				}
			)
			.catch(
				() => {
					cartRefresh();
					toast.error('商品の追加に失敗しました。', {
						position: "top-center",
						autoClose: 3000,
					});
				}
			)
	};


	const productImage = (item) => {

		if (item.Image.main === null) {
			return <img src={noImg} alt={item['Basic.Name']} />
		}
		else {
			return <img src={item.Image.main[0].Variation[imageVariation]} alt={item['Basic.Name']} />
		}
	}

	const setLength = (item, event) => {
		const array = {};

		array[item.Catalog_Product__] = event.target.value;

		setSelectLength({
			...selecLength,
			...array
		})
	}

	const buildSelect = (item) => {
		
		var list = [];

		for(let i = 1; i <= selectMax; i++ ) {
			list.push(<option key={i} value={i} >{i}</option>);
		}

		return (
			<div
				className={common['select-wrapper']}
			>
				<select
					className={common['select']}
					onChange={(event) => setLength(item, event)}
				>
					{list}
				</select>個
			</div>
		)
	}

	const buildCarch = (item) => {
		if (!item['Description.CatchPhrase']) {
			return null
		}
		else {
			return (
				<p
					className={styles['product-catch']}
				>{item['Description.CatchPhrase']}</p>
			)
		}
	}

	const buildShort = (item) => {
		if (!item['Description.Short']) {
			return null
		}
		else {
			return (
				<p
					className={styles['product-short']}
					dangerouslySetInnerHTML={{__html: item['Description.Short']}} />
			)
		}
	}

	const buildPrice = (item) => {
		if (item['Price.Discount']==='Y') {
			if (item['Public_Price'] !== undefined) {
				return <p className={styles['product-price']}><span style={{textDecoration: "line-through"}}>{item.Public_Price.tax.display}</span> {item.Price.tax.display}（税込）</p>
			}
		}
		return <p className={styles['product-price']}>{item.Price.tax.display}（税込）</p>
	}


	const buildItem = () => {
		
		if (products === null) {
			return <Loading />
		}
		else {
			return products.data.data.map((item, idx)=>{
				return (
					<div
						className={styles['product-item']}
						key={idx}
					>
						{productImage(item)}
						<p
							className={styles['product-name']}
						>{item['Basic.Name']}</p>
						{buildCarch(item)}
						{buildShort(item)}
						{buildPrice(item)}
						{buildSelect(item)}
						<div
							className={common['btn-warapper']}
						>
							<button
								onClick={() => {
									addToCart(item.Catalog_Product__)
								}}
							>
								カートに追加する
							</button>
						</div>
					</div>
				)
			})
		}
	}


	if (products === null) {
		return (
			<div
				className={`${common['main-wrapper']}`}
			>
				<Loading />
			</div>
		)
	}
	else {
		return (
			<>
				<Helmet>
					<title>GOODS | ラップオバケ OFFICIAL WEB SITE</title>
				</Helmet>
				<div
					className={styles['mv']}
				>
					<img src={mv} alt="RAPOBAKE GOODS" />
					<div className={styles['obake1']}>
						<img src={obake1} alt="ラップオバケ" />
					</div>
					<div className={styles['obake2']}>
						<img className={styles['obake2-item']} src={obake2} alt="ラップオバケ" />
						<img className={styles['obake2-txt']} src={txt} alt="キミハナニガホシイ！？" />
					</div>
				</div>
				<div
					className={`${common['main-wrapper']} ${common['main-wrapper-top']}`}
				>
					<div
						className={styles['product-list']}
					>
						{buildItem()}
					</div>
				</div>
				<ToastContainer />
				<div className={styles['obake3']}>
					<img src={obake3} alt="ラップオバケ" />
				</div>
				<div className={styles['obake4']}>
					<img src={obake4} alt="ラップオバケ" />
				</div>
			</>
		);
	}
}

export default Goods;
