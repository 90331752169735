import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { rest } from '@karpeleslab/klbfw';
import classNames from 'classnames';

// style
import common from "scss/common.module.scss";
import styles from 'scss/account.module.scss';
import 'react-toastify/dist/ReactToastify.css';

// component
import Helmet from 'components/helmet/helmet';
import OverLay from 'components/loading/overlay';
import Password from 'components/password/password';

const UserPassword = () => {
	const [form, setForm] = useState({
		new_password: '',
		new_password_confirm: ''
	});
	const [passwordCheck, setPasswordCheck] = useState(true);
	const [confirmPass, setConfirmPass] = useState(false);
	const [loading, setLoading] = useState(false);
	const [passwordOverlay, setPasswordOverlay] = useState(false);
	const [password, setPassword] = useState('');

	const formChange = (event) => {
		const array = {}
		array[event.target.getAttribute('name')] = event.target.value;

		setForm({
			...form,
			...array
		});
	};

	const submitPassword = (event) => {
		event.preventDefault();
		setPasswordOverlay(true);
	}

	const buildOverLay = () => {
		if (!loading) {
			return null
		} else {
			return <OverLay />
		}
	}

	useEffect(() => {

		if ( form.new_password.length >= 6 && form.new_password_confirm.length >= 6 && form.new_password.length !== 0 && form.new_password_confirm.length !== 0 && form.new_password === form.new_password_confirm ) {
			setPasswordCheck(false)
		} else {
			setPasswordCheck(true)
		}

		if (form.new_password === form.new_password_confirm) {
			setConfirmPass(false);
		} else {
			setConfirmPass(true);
		}

	},[form])

	const submitForm = (event) => {
		event.preventDefault();

		setLoading(true);

		const data = {
			old_password: password,
			password: form.new_password
		};
		
		rest("User/@:setPassword", "POST", data)
			.then(() => {
				setPasswordOverlay(false);
				setLoading(false);
				setForm({
					new_password: '',
					new_password_confirm: ''
				});
				toast.success(<div>更新が完了しました。</div>, {
					position: "top-center",
					autoClose: 1500,
				});
			})
			.catch(err => {
				setLoading(false);
				toast.error(err.message, {
					position: "top-center",
					autoClose: 3000,
				});
			});

	}

	const buildPassword = () => {
		if (!passwordOverlay) {
			return null
		}
		else {
			return <Password
				setPasswordOverlay={setPasswordOverlay}
				setLoading={setLoading}
				submitForm={submitForm}
				password={password}
				setPassword={setPassword}
			/>
		}
	}

	return (
		<>
			{buildOverLay()}
			{buildPassword()}
			<Helmet>
				<title>パスワード | ラップオバケ OFFICIAL WEB SITE</title>
				<meta property="og:title" content="パスワード | ラップオバケ OFFICIAL WEB SITE" />
				<meta name="twitter:title" content="パスワード | ラップオバケ OFFICIAL WEB SITE" />
			</Helmet>

			<h1
				className={styles['account-body-title']}
			>パスワード</h1>
			<p
				className={styles['account-body-text']}
			>パスワードを変更する場合は入力を行い更新をクリックしてください。</p>

			<form onSubmit={(e) => {submitPassword(e)}}>
				<div
					className={common['form-item']}
				>
					<dl className={common['form-item-group']}>
						<dt className={common['form-item-group-label']}>新しいパスワード</dt>
						<dd className={common['form-item-group-field']}>
							<input
								className={classNames(common['input'], {
									[common['input-error']]: form.new_password.length < 6 && form.new_password.length !== 0
								})}
								placeholder="新しいパスワード"
								type="password"
								name="new_password"
								value={form.new_password}
								onChange={(e) => {formChange(e)}}
							/>
							{form.new_password.length < 6 && form.new_password.length !== 0 && <span className={common['input-error-text']}>エラー:パスワードは6文字以上を入力してください</span>}
						</dd>
					</dl>
				</div>
				<div
					className={common['form-item']}
				>
					<dl className={common['form-item-group']}>
						<dt className={common['form-item-group-label']}>新しいパスワード（確認用）</dt>
						<dd className={common['form-item-group-field']}>
							<input
								className={classNames(common['input'], {
									[common['input-error']]: confirmPass
								})}
								placeholder="新しいパスワード（確認用）"
								type="password"
								name="new_password_confirm"
								value={form.new_password_confirm}
								onChange={(e) => {formChange(e)}}
							/>
							{confirmPass && <span className={common['input-error-text']}>エラー:パスワードが正しくありません</span>}
						</dd>
					</dl>
				</div>
				<div className={`${common['btn-warapper']} ${common['btn-warapper__right']}`}>
					<button className={common['btn-submit']} type="submit" disabled={passwordCheck || loading}>更新</button>
				</div>
			</form>
			<ToastContainer />
		</>
	);
}

export default UserPassword;