import React from "react";
import { getSettings } from "@karpeleslab/klbfw";

// style
import styles from 'scss/layout.module.scss';

// component
import Header from 'components/layout/Header';
import Footer from 'components/layout/Footer';
import Helmet from 'components/helmet/helmet';


const Layout = ({children}) => {

	return (
		<>
			<Helmet>
				<title>ラップオバケ OFFICIAL WEB SITE</title>
				<meta name="description" content="ラップオバケ OFFICIAL WEB SITE RAPOBAKEのLIVEやNEWS,GOODS情報を発信"/>
				<meta property="og:type" content="website" />
				<meta property="og:title" content="ラップオバケ OFFICIAL WEB SITE" />
				<meta property="og:description" content="ラップオバケ OFFICIAL WEB SITE RAPOBAKEのLIVEやNEWS,GOODS情報を発信" />
				<meta property="og:url" content="https://rapobake.com/" />
				<meta property="og:site_name" content="ラップオバケ OFFICIAL WEB SITE" />
				<meta property="og:image" content={getSettings().cover.Variation["strip&format=jpeg&scale_crop=1920x1080"]} />
				<meta property="og:image:secure_url" content={getSettings().cover.Variation["strip&format=jpeg&scale_crop=1920x1080"]} />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="ラップオバケ OFFICIAL WEB SITE" />
				<meta name="twitter:description" content="ラップオバケ OFFICIAL WEB SITE RAPOBAKEのLIVEやNEWS,GOODS情報を発信" />
				<meta name="twitter:image" content={getSettings().cover.Variation["strip&format=jpeg&scale_crop=1920x1080"]} />
			</Helmet>
			<div
				className={styles['layout-main']}
			>
				<Header />
				<main
					className={styles['layout-inner']}
				>
					{children}
				</main>
				<Footer />
			</div>
		</>
	);
};

export default Layout;