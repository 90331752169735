import React from 'react';
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import { getPrefix } from "@karpeleslab/klbfw";
import { useParams, useLocation } from "react-router-dom";
import moment from "moment";
import 'react-toastify/dist/ReactToastify.css';

// style
import common from "scss/common.module.scss";
import styles from "scss/cms.module.scss";

// component
import Loading from "components/loading/loading";
import Helmet from 'components/helmet/helmet';


const useQuery = () => new URLSearchParams(useLocation().search);

const Article = (props) => {
	const { type } = props;
	const { slug } = useParams();
	const query = useQuery();

	const MAIN_IMG_VARIATION = 'format=jpeg&strip&scale_crop=1000x625';

	const imgVariation = [
		MAIN_IMG_VARIATION,
	];

	const args = {
		slug: decodeURIComponent(slug),
		image_variation: imgVariation,
	};

	if (query.get("_preview")) {
		args._preview = query.get("_preview");
	}


	const [article] = useRest(`Content/Cms/@news:loadSlug`, args);

	const buildMainImg = () => {
		const driveItem = article.data.content_cms_entry_data.Drive_Item;

		if (driveItem === null || driveItem === void 0 || driveItem.length === 0) {
			return null
		} else {
			return (
				<div
					className={common['cms-head-img']}
				>
					<img src={driveItem.Media_Image.Variation[MAIN_IMG_VARIATION]} alt={article.data.content_cms_entry_data.Title} />
				</div>
			)
		}
	}

	const buildArticle = () => {

		if (article.data.content_cms_entry_data === void 0) {
			return (
				<>
					<h1
						className={styles['cms-title']}
					>記事データが見つかりません</h1>
				</>
			)
		} else {
			return (
				<>
					<Helmet>
						<title>{article.data.content_cms_entry_data.Title} | ラップオバケ OFFICIAL WEB SITE</title>
						{/* og */}
						<meta property="og:type" content="website" />
						<meta property="og:title" content={`${article.data.content_cms_entry_data.Title} | ラップオバケ OFFICIAL WEB SITE`} />
						{
							article.data.content_cms_entry_data.Short_Contents && <meta property="og:description" content={article.data.content_cms_entry_data.Short_Contents}/>
						}
						{
							article.data.content_cms_entry_data.Top_Drive_Item && <meta property="og:image" content={ article.data.content_cms_entry_data.Top_Drive_Item.View_Url } />
						}
						{
							article.data.content_cms_entry_data.Top_Drive_Item && <meta property="og:image:secure_url" content={ article.data.content_cms_entry_data.Top_Drive_Item.View_Url } />
						}
						<meta property="og:url" content={'https://rapobake.com' + getPrefix() + '/' + type + '/' + article.data.content_cms_entry_data.Slug}/>
						{/* twitter */}
						<meta name="twitter:title" content={`${article.data.content_cms_entry_data.Title} | ラップオバケ OFFICIAL WEB SITE`} />
						{
							article.data.content_cms_entry_data.Short_Contents && <meta name="twitter:description" content={article.data.content_cms_entry_data.Short_Contents}/>
						}
						{
							article.data.content_cms_entry_data.Top_Drive_Item && <meta name="twitter:image" content={article.data.content_cms_entry_data.Top_Drive_Item.View_Url} />
						}
						<meta property="article:published_time" content={moment(article.data.content_cms_entry_data.Published?.unix * 1000).format('LLL')}/>
						{console.log(article.data.content_cms_entry_data.Published)}
					</Helmet>
					{buildMainImg()}
					<h1
						className={styles['cms-title']}
					>{article.data.content_cms_entry_data.Title}</h1>
					<div className="cms-content" dangerouslySetInnerHTML={{__html: article.data.content_cms_entry_data.Contents}} /> 
				</>
			)
		}
	}


	if (article === null || article === void 0) {
		return <Loading />
	}
	else {
		return (
			<>
				{buildArticle()}
			</>
		);
	}
};


export default Article;