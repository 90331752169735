import React from "react";
import { Link, useLocation } from "react-router-dom";
import { getPrefix } from "@karpeleslab/klbfw";
import {
	FacebookShareButton,
	FacebookIcon,
	LineShareButton,
	LineIcon,
	TwitterShareButton,
	TwitterIcon
} from "react-share";

// style 
import styles from 'scss/footer.module.scss'; 

const Footer = () => {

	const location = useLocation();
	const shareUrl = `https://rapobake.com${getPrefix()}${location.pathname}`;

	return (
		<footer
			className={styles['footer']}
		>
			<ul
				className={styles['footer-sns']}
			>
				<li>
					<TwitterShareButton url={shareUrl} >
						<TwitterIcon size={40} borderRadius={6} />
					</TwitterShareButton>
				</li>
				<li>
					<FacebookShareButton url={shareUrl} >
						<FacebookIcon size={40} borderRadius={6} />
					</FacebookShareButton>
				</li>
				<li>
					<LineShareButton url={shareUrl} >
						<LineIcon size={40} borderRadius={6} />
					</LineShareButton>
				</li>
			</ul>
			<ul
				className={styles['footer-nav']}
			>
				<li>
					<Link
						to="/legal/terms_of_service">
						利用規約
					</Link>
				</li>
				<li>
					<Link
						to="/legal/privacy_policy">
						プライバシーポリシー
					</Link>
				</li>
				<li>
					<Link
						to="/legal/scta">
						特定商取引法に基づく表記
					</Link>
				</li>
			</ul>
			<small>© 2019 ラップオバケ</small>
		</footer>
	);
};

export default Footer;