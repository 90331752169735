import React from 'react';
import ReactPlayer from 'react-player';
import { Link } from "react-router-dom";
import NoSsr from '@material-ui/core/NoSsr';

// style
import common from "scss/common.module.scss";
import styles from "scss/index.module.scss";

//img
import musicBlack from "resources/img/music_black.jpg";
import musicRed from "resources/img/music_red.jpg";
import musicWhite from "resources/img/music_white.jpg";

// component
import Title from "components/title/title";
import News from "./section/News";
import Live from './section/Live';
import MainVisual from './section/MainVisual';

const Index = () => {

	return (
		<>
			<NoSsr>
				<MainVisual />
			</NoSsr>
			<div
				className={`${common['main-wrapper']}`}
			>
				<NoSsr>
					<News />
				</NoSsr>
				<NoSsr>
					<Live />
				</NoSsr>
				<section
					className={common['common-wrapper']}
				>
					<Title
						title="MUSIC"
					/>
					<div
						className={styles['music-wrapper']}
					>
						<a
							href="https://www.tunecore.co.jp/artist/rapobake"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img src={musicBlack} alt="トメナ、" />
							<h3>トメナ、</h3>
							<p>[ ALBUM ]</p>
						</a>
						<a
							href="https://www.tunecore.co.jp/artist/rapobake#r443825"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img src={musicRed} alt="チャッカ、" />
							<h3>チャッカ、</h3>
							<p>[ SINGLE ]</p>
						</a>
						<a
							href="https://www.tunecore.co.jp/artist/rapobake#r443825"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img src={musicRed} alt="キミガ、" />
							<h3>キミガ、</h3>
							<p>[ SINGLE ]</p>
						</a>
						<Link
							to="/news/tokyo"
						>
							<img src={musicWhite} alt="トーキョー" />
							<h3>トーキョー</h3>
							<p>[ SINGLE ]</p>
						</Link>
					</div>
				</section>
				<section
					className={common['common-wrapper']}
				>
					<Title
						title="VIDEO"
					/>
					<div
						className={styles['video-wrapper']}
					>
						<div
							className={styles['video-item']}
						>
							<ReactPlayer width="100%" height="auto" url='https://youtu.be/4s1yeQ-s7_g' />
							<p>【おはようございますとこんにちは/ラップオバケ】</p>
						</div>
						<div
							className={styles['video-item']}
						>
							<ReactPlayer width="100%" height="auto" url='https://www.youtube.com/watch?v=pkgJfa4hFnw' />
							<p>【チョットウォッチミー/ ラップオバケ】</p>
						</div>
						<div
							className={styles['video-item']}
						>
							<ReactPlayer width="100%" height="auto" url='https://www.youtube.com/watch?v=ywDuSfzHzhU' />
							<p>【オトナッテナニ？ / ラップオバケ】</p>
						</div>
						<div
							className={styles['video-item']}
						>
							<ReactPlayer width="100%" height="auto" url='https://www.youtube.com/watch?v=dfspBu-FL3o' />
							<p>【モウイッカイ/ラップオバケ】</p>
						</div>
						<div
							className={styles['video-item']}
						>
							<ReactPlayer width="100%" height="auto" url='https://youtu.be/WMdne7ADCe0' />
							<p>【モノメモリー / ラップオバケ】</p>
						</div>
						<div
							className={styles['video-item']}
						>
							<ReactPlayer width="100%" height="auto" url='https://youtu.be/WKwunpGoVss' />
							<p>【キョリ~Lyric video~/ラップオバケ】</p>
						</div>
						<div
							className={styles['video-item']}
						>
							<ReactPlayer width="100%" height="auto" url='https://www.youtube.com/watch?v=hBmadB4F1uc' />
							<p>【トーキョー】</p>
						</div>
						<div
							className={styles['video-item']}
						>
							<ReactPlayer width="100%" height="auto" url='https://www.youtube.com/watch?v=Ff2k_-meyf8' />
							<p>【降霊曲】テンダッパゲ/ラップオバケ 〜Lyric video〜</p>
						</div>
						<div
							className={styles['video-item']}
						>
							<ReactPlayer width="100%" height="auto" url='https://www.youtube.com/watch?v=mOtZEXrL1-c' />
							<p>【キミガ、】DEMO Ver.</p>
						</div>
						<div
							className={styles['video-item']}
						>
							<ReactPlayer width="100%" height="auto" url='https://www.youtube.com/watch?v=_o2d1ARunMk' />
							<p>【チャッカ、】DEMO Ver.</p>
						</div>
					</div>
				</section>
			</div>
		</>
	);
}

export default Index;
