import React from 'react';
import {Get} from '@karpeleslab/klbfw'
import { useTranslation } from 'react-i18next';

const Html = () => {
	const { t } = useTranslation();
	const recoverUrl =
        'https://www.rapobake.com/recover-password/' +
        Get('_recover_user').User_Id + '?k=' +
        encodeURIComponent(Get('_user_key'));

	return (
		<div id="mail-html">
			<div style={{
				textAlign:'center',
				marginLeft: 'auto',
				marginRight: 'auto',
				marginBottom: '20px'
			}}>
				<div style={{
					margin: 'auto',
					textAlign:'Left',
				}}>
					<p>{ t('mail_forgotten_text_01') }</p>
					<p> <a href={recoverUrl}>{recoverUrl}</a></p>
					<p>{ t('mail_forgotten_text_02') }</p>
					<p>{ t('mail_name') }</p>
				</div>
			</div>
		</div>
	);
};

export default Html;
