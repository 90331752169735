import React from 'react';
import { useTranslation } from 'react-i18next';

const Text = () => {
	const { t } = useTranslation();
	return (
		<div id="mail-text">
			ーーーーーーーーーーーーーーーーーーー■-□ { t('mail_name') } □-■ーーーーーーーーーーーーーーーーーーー

			{ t('mail_forgotten_completed_text_01') }
			{ t('mail_forgotten_completed_text_02') }

			https://www.rapobake.com/login

			{ t('mail_name') }
		</div>
	);
};

export default Text;