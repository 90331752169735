import React from 'react';
import Routing from './Routing';
import * as serviceWorker from './serviceWorker';
import { run } from "@karpeleslab/react-klbfw-hooks";
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Backend } from '@karpeleslab/i18next-klb-backend';
import { getLocale } from "@karpeleslab/klbfw";
import {rest} from '@karpeleslab/klbfw';


import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-svg-core/styles.css';

import 'scss/common.scss';

library.add(fas);

let i18nOpt = {
	lng: getLocale(),
	initImmediate: false,
	load: 'currentOnly',
	interpolation: {
		escapeValue: false, // not needed for react as it escapes by default
	},
	react: {
		useSuspense: false,
	}
};

global.rest = rest;

run(<Routing />, [i18n.use(Backend).use(initReactI18next).init(i18nOpt)]);

if(typeof window !== 'undefined') {
	// If you want your app to work offline and load faster, you can change
	// unregister() to register() below. Note this comes with some pitfalls.
	// Learn more about service workers: https://bit.ly/CRA-PWA
	serviceWorker.unregister();
}
