import React　from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

// style
import common from "scss/common.module.scss";

const Thanks = () => {
	const { t } = useTranslation();
	return (
		<>
			<div
				className={`${common['main-wrapper']}`}
			>
				<h2 className={common['title']}>{t('title_order_complate')}</h2>
				<div
					className={`${common['sub-title-wrapper']} ${common['sub-title-wrapper--thanks']}`}
				>
					<p>{t('txt_order_complate')}</p>
				</div>
				<div className={common['btn-warapper']}>
					<Link
						to="/"
					>
						{t('to_goods')}
					</Link>
				</div>
			</div>
		</>
	);
}

export default Thanks;
