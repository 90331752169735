import React from 'react';
import {Get} from "@karpeleslab/klbfw";
import { useTranslation } from 'react-i18next';

const Text = () => {
	const { t } = useTranslation();
	return (
		<div id="mail-text">
			{t('mail_user_name', {name:`${Get('_order').Billing_User_Location.Last_Name} ${Get('_order').Billing_User_Location.First_Name}`})}

			{t('mail_order_thanks')}
			{t('mail_order_complete_text')}

			{t('mail_order_complete_text_02')}

			■{t('mail_contact')}
			https://rapobake.com/contact/

			https://rapobake.com/
		</div>
	);
};

export default Text;