import React, { useState, useEffect } from 'react';
import { rest } from "@karpeleslab/klbfw";
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { getLocale } from "@karpeleslab/klbfw";

// component
import Loading from "components/loading/loading";
import Cart from "components/cart/list";
import Countries from "components/countries/countries";

// style
import styles from "scss/checkout.module.scss";
import common from "scss/common.module.scss";

const Checkout = () => {
	const [cart] = useRest("Catalog/Cart/@");
	const [user] = useRest("User:get");
	const [country] = useState(getLocale().split("-")[1]);
	const [currentCountry] = useRest("ThirdParty/Geoip:lookup");
	const { t } = useTranslation();
	const history = useHistory();
	const [loading, setLoading] = useState(false);

	const [formVal, setFormVal] = useState({
		Nickname: "",
		First_Name: "",
		Last_Name: "",
		Zip: "",
		Country__: "JP",
		Address: "",
		Address2: "",
		City: "",
		Province: "",
		Contact_Phone: ""
	});


	useEffect(() => {

		if (currentCountry !== null) {
			setFormVal({
				...formVal,
				Country__: currentCountry.data.country.iso_code,
			});
		}
		// eslint-disable-next-line
	}, [currentCountry]);
	

	const [formSort] = useState({
		 JP: [
			[{
				name:'Nickname',
				required: true
			}],
			[
				{
					name:'Last_Name',
					required: true
				},
				{
					name:'First_Name',
					required: true
				}
			],
			[
				{
					name:'Zip',
					required: true
				},
				{
					name:'Province',
					required: true
				},
			],
			[
				{
					name:'City',
					required: true
				},
				{
					name:'Address',
					required: true
				},
			],
			[
				{
					name:'Address2',
					required: false
				},
				{
					name:'Contact_Phone',
					required: true
				}
			],
		],
		other: [
			[{
				name:'Nickname',
				required: true
			}],
			[
				{
					name:'First_Name',
					required: true
				},
				{
					name:'Last_Name',
					required: true
				}
			],
			[
				{
					name:'Contact_Phone',
					required: true
				},
				{
					name:'Address',
					required: true
				},
			],
			[
				{
					name:'Address2',
					required: false
				},
				{
					name:'City',
					required: true
				},
			],
			[
				{
					name:'Province',
					required: true
				},
				{
					name:'Zip',
					required: true
				},
			],
		]
	});

	

	const createLocation = (e) => {
		if (e) e.preventDefault();

		setLoading(true)

		rest('User/@/Location', 'POST', formVal)
			.then((data) => {
				rest('User/@:setDefaultLocation', 'POST',{"User_Location__":data.data.User_Location__, "type": "Billing"})
					.then(
						(data) => {

							rest('Catalog/Cart/@:createOrder', 'POST')
								.then(
									(data) => {
										setLoading(false)
										history.push("/order/"+data.data.Order__)
									}
								)
								.catch(
									(data) => {
										setLoading(false)
										setTimeout( () => {
											toast.error(data.message, {
												position: "top-center",
												autoClose: 3000,
											});
										}, 100);
									}
								)
						}
					)
					.catch(
						(data) => {
							setLoading(false)
							setTimeout( () => {
								toast.error(data.message, {
									position: "top-center",
									autoClose: 3000,
								});
							}, 100);
						}
					)
			})
			.catch((err) => {
				setLoading(false)
				setTimeout( () => {
					toast.error(err.message, {
						position: "top-center",
						autoClose: 3000,
					});
				}, 100
				);
			})
	}



	const valChange = (event) => {
		const array = {}
		array[event.target.getAttribute('name')] = event.target.value;
		setFormVal({
			...formVal,
			...array
		});
	}

	const buildRequired = (required) => {
		if (required) {
			return <span className={styles['required']} >*</span>
		}
		else {
			return null
		}
	}


	const buildFromItem = (item) => {
		return item.map((rowItem, idx) => {
			if (rowItem.name === "Country__") {
				return (
					<Countries
						key={idx}
						valChange={valChange}
						lang={formVal.Country__}
						rowItem={rowItem}
						item={item}
						buildRequired={buildRequired}
					/>
				)
			}
			else {
				return (
					<dl
						key={idx}
						className={classNames(`${styles['list-item']}`,
							{
								[styles['list-item-bisected']]: item.length === 2
							}
						)}>
						<dt>{t(`${rowItem.name}_lbl`)}{buildRequired(rowItem.required)}</dt>
						<dd>
							<input
								className={common['input']}
								onChange={valChange}
								type="text"
								value={formVal[rowItem.name]}
								name={rowItem.name}
							/>
						</dd>
					</dl>
				)
			}
		})
	}

	const buildForm = () => {

		if (currentCountry === null || country === null) return false;

		let inputSort = formSort['JP'];
		
		if (country !== "JP") {
			inputSort = formSort['other'];
		}

		return inputSort.map((item, idx) => {
			return (
				<div
					className={styles['list']}
					key={idx}
				>
					{buildFromItem(item)}
				</div>
			)
		})
	}



	if (user === null || cart === null || loading === true) {
		return (
			<div
				className={`${common['main-wrapper']}`}
			>
				<h2 className={common['title']}>{t('title_purchase')}</h2>
				<div className={common['wrapper']}>
					<Loading />
				</div>
			</div>
		);
	}

	if (!user.data) {
		return <Redirect to="/login?return_to=/checkout"/>
	}
	
	return (
		<>
			<div
				className={`${common['main-wrapper']}`}
			>
				<h2 className={common['title']}>{t('title_purchase')}</h2>
				<div
					className={common['sub-title-wrapper']}
				>
					<h3
						className={common['sub-title']}
					>{t('title_order')}</h3>
				</div>
				<Cart
					removeItem={null}
					cart={cart}
				/>

				<div
					className={common['sub-title-wrapper']}
				>
					<h3
						className={common['sub-title']}
					>{t('title_order_input')}</h3>
				</div>
				<form onSubmit={createLocation}>
					<div className={common['wrapper']}>
						{buildForm()}
					</div>
					<div className={common['btn-warapper']}>
						<button className={common['btn-submit']} type="submit">{t('btn_payment')}</button>
					</div>
				</form>
				<ToastContainer />
			</div>
		</>
	);
}

export default Checkout;
