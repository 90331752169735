import React from 'react';


// style
import common from "scss/common.module.scss";

//img
import mv from "resources/img/mainimage.jpg";

// component

const LowerVisual = () => {
	
	return (
		<>
			<div
				className={common['mv']}
				style={{
					backgroundImage: `url(${mv})`
				}}
			>
			</div>
		</>
	);
}

export default LowerVisual;
