import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// style
import styles from "scss/coupon.module.scss";
import common from "scss/common.module.scss";


const Coupon = (props) => {

	const { addToCart, cart } = props;
	const [couponCode, setCouponCode] = useState('');
	const { t } = useTranslation();

	if (cart === null ||cart.data.products.length === 0) {
		return null
	}
	else {
		return (
			<>
				<div
					className={common['sub-title-wrapper']}
				>
					<h3
						className={common['sub-title']}
					>{t('cart_coupon_title')}</h3>
					<p className={common['sub-title-info']}>{t('cart_coupon_text')}</p>
				</div>
				<div className={common['wrapper']}>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							addToCart(`coupon,coupon-code=${encodeURIComponent(couponCode)}`)
						}}
					>
						<ul
							className={styles['coupon']}
						>
							<li>
								<input
									className={common['input']}
									type="text"
									value={couponCode}
									onChange={(e) => {setCouponCode(e.target.value)}}
								/>
							</li>
							<li>
								<div className={`${common['btn-warapper']}`}>
									<button
										className={common['btn-glay']}
										disabled={ couponCode.length === 0 ? true : false }
										type="submit"
									>適用</button>
								</div>
							</li>
						</ul>
					</form>
				</div>
			</>
		);

	}
}

export default Coupon;