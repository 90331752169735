import React from 'react';

// style
import common from "scss/common.module.scss";
import styles from 'scss/contact.module.scss';

//img
import mv from "resources/img/mainimage.jpg";

// component
import Title from "components/title/title";
import Helmet from 'components/helmet/helmet';

const Profile = () => {
	
	return (
		<>
			<Helmet>
				<title>CONTACT | ラップオバケ OFFICIAL WEB SITE</title>
				<meta property="og:title" content="CONTACT | ラップオバケ OFFICIAL WEB SITE" />
				<meta name="twitter:title" content="CONTACT | ラップオバケ OFFICIAL WEB SITE" />
			</Helmet>
			<div
				className={common['mv']}
				style={{
					backgroundImage: `url(${mv})`
				}}
			>
			</div>
			<div
				className={`${common['main-wrapper']}`}
			>
				<section
					className={common['common-wrapper']}
				>
					<Title
						title="CONTACT"
					/>
					<p
						className={styles['contact-text']}
					>イベント、学園祭出演オファーやブッキングは<a href="mailto:rapobake.official@gmail.com">rapobake.official@gmail.com</a>までご連絡下さい。 ご氏名、連絡先、イベントの日時、場所、（もしあれば企画書）等の概要をご明記の上、メールを送信頂ければラップオバケ担当者よりご連絡させて頂きます。</p>
				</section>
			</div>
		</>
	);
}

export default Profile;
