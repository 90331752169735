import React, { useEffect, useState } from 'react';
import { rest } from '@karpeleslab/klbfw';
import { useParams } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

// style
import common from "scss/common.module.scss";
import styles from "scss/live.module.scss";

//img
import mv from "resources/img/mainimage.jpg";

// component
import Title from "components/title/title";
import Helmet from 'components/helmet/helmet';
import Loading from "components/loading/loading";

const Profile = () => {
	
	const { tag } = useParams();
	const [date, setDate] = useState({
		currentDate: tag === void 0 ? moment().format('YYYY-MM') : tag,
		prevDate: tag === void 0 ? moment().subtract(1, 'M').format('YYYY-MM'): moment(tag).subtract(1, 'M').format('YYYY-MM'),
		nextDate: tag === void 0 ? moment().add(1, 'M').format('YYYY-MM') : moment(tag).add(1, 'M').format('YYYY-MM'),
	});
	const [data, setData] = useState(null);

	useEffect(()=> {
		setDate({
			currentDate: tag === void 0 ? moment().format('YYYY-MM') : tag,
			prevDate: moment(tag).subtract(1, 'M').format('YYYY-MM'),
			nextDate: moment(tag).add(1, 'M').format('YYYY-MM'),
		})
	},[tag, setDate])

	useEffect(() => {
		setData(null);

		const imageVariation = 'scale_crop=375x211';
		let dateArray = {
			month: date.currentDate.split('-')[1].toString(),
			year: date.currentDate.split('-')[0].toString(),
			image_variation : imageVariation,
			Visible: "Y",
			sort: { Date: "DESC"}
		}

		rest('Planner/@planner/Schedule', 'GET', dateArray)
			.then(
				(data) => {
					let filterData = _.filter(data.data, {'Planner_Event__': null});
					setData(filterData);
				}
			)
		
	}, [date]);


	const getDayLocale = (date) => {
		return moment(date).format('YYYY.MM.DD');
	}

	const buildNav = () => {
		return (
			<div
				className={styles['liveHead']}
			>
				<Link
					to={`/live-${date.prevDate}`}
				><FontAwesomeIcon icon={['fas', 'chevron-circle-left']}/> {date.prevDate}</Link>
				<p>{date.currentDate}</p>
				<Link
					to={`/live-${date.nextDate}`}
				>{date.nextDate} <FontAwesomeIcon icon={['fas', 'chevron-circle-right']}/></Link>
			</div>
		)
	}


	const buildLiveList = () => {

		let content;

		if (data === null | data === void 0) {
			content = <Loading />
		}
		else if (data.length === 0) {
			content = <p className={styles['liveNo']} >コンテンツがありません。<br/>前のページに戻ってください。</p>
		}
		else {
			content = data.map(item => {
				return (
					<dl
						key={item.Planner_Schedule__ }
						className={styles['liveList']}
					>
						<dt>
							{ getDayLocale(item.Date.iso) }
						</dt>
						<dd>
							<Link
								to={`/live/${item.Content_Cms_Entry_Data.Slug}`}
							>
								{item.Content_Cms_Entry_Data.Title}
							</Link>
						</dd>
					</dl>
				)
			})
		}

		return (
			<>
				{buildNav ()}
				{content}
			</>
		)

	}

	return (
		<>
			<Helmet>
				<title>LIVE | ラップオバケ OFFICIAL WEB SITE</title>
				<meta property="og:title" content="LIVE | ラップオバケ OFFICIAL WEB SITE" />
				<meta name="twitter:title" content="LIVE | ラップオバケ OFFICIAL WEB SITE" />
			</Helmet>
			<div
				className={common['mv']}
				style={{
					backgroundImage: `url(${mv})`
				}}
			>
			</div>
			<div
				className={`${common['main-wrapper']}`}
			>
				<section
					className={common['common-wrapper']}
				>
					<Title
						title="LIVE"
					/>
					{ buildLiveList() }
				</section>
			</div>
		</>
	);
}

export default Profile;
