import React, { useEffect, useState }  from 'react';
import { rest, Get } from "@karpeleslab/klbfw";
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import { Link, useHistory, Redirect } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';


// component
import Loading from "components/loading/loading"

// style
import styles from "scss/login.module.scss";
import common from "scss/common.module.scss";


const Login = () => {

	const [session, setSession] = useState(null);
	const [flow, updateFlow] = useState(null);
	const [user, setUser] = useRest("User:get");
	const [form, setForm] = useState({
		email: "",
		name: "",
		password: "",
		password2: "",
		i_accept_the_terms_of_service: false,
	});
	const history = useHistory();


	const postHandller = (obj) => {
		rest("User:flow", "POST", obj)
			.then(
				handleResponse
			).catch(
				handleError
			);
	}

	const submitTrigger = (action, event = null) => {

		if (event !== null) {
			event.preventDefault()
		}

		switch(action.type) {
		case 'submit':
			postHandller({ ...form, session: session })
			return;
		case 'post':
			postHandller({ session: session, ...action, type: undefined })
			return;
		case 'reset':
			postHandller({})
			return;
		default:
			return null;
		}
	}

	const handleResponse = (response) => {

		if (response.data.url) {
			window.location.href = response.data.url;
			return;
		}

		if (response.data.complete) {
			setUser({
				data: response.data.user
			});
			return;
		}
		
		if (response.data.session) {
			setSession(response.data.session);
		}

		updateFlow(response);
	}
	
	const handleError = (response) => {

		toast.error(response.message, {
			position: "top-center",
			autoClose: 3000,
		});

	}

	const formChange = (event) => {

		const array = {}
		array[event.target.getAttribute('name')] = event.target.value;

		setForm({
			...form,
			...array
		});

	};

	const loginOAuth2 = ({button, info}) => {
		return <button
			style={{
				backgroundImage: `url(${button.logo})`,
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center center',
				backgroundSize: '70% 70%',
				borderRadius: 50,
				backgroundColor: button['background-color']
			}}
			onClick={() => submitTrigger({type: "post", oauth2: info.OAuth2_Consumer__})}
		>&nbsp;</button>;
	}


	useEffect(() => {
		if (Get("session")) {
			submitTrigger({type: "post", session: Get("session")});
		}
		submitTrigger({type: "post"});
		// eslint-disable-next-line
	}, []);


	const buildItem = (field) => {
		const {type} = field;
		
		switch(type) {
		case "label":
			let labelTxt = field.label;
			if (field.style === "error") {
				if (field.link) {
					labelTxt = <Link href={field.link} target="_blank" rel="noopener" color="inherit">{labelTxt}</Link>;
				}
				return (
					<label
						className={styles['form-label']}
					>{labelTxt}</label>
				);
			}
			if (field.link) {
				labelTxt = <Link href={field.link} target="_blank" rel="noopener">{labelTxt}</Link>;
			}
			return (
				<label
					className={styles['form-label']}
				>{labelTxt}</label>
			);
		case "text":
			return <input className={common['input']} placeholder={field.label} type="text" name={field.name} onChange={formChange} 
				value={form[field.name]} />;
		case "email":
			return <input className={common['input']} placeholder={field.label} type="email" name={field.name} onChange={formChange} 
				value={form[field.name]} />;
		case "password":
			return <input className={common['input']} placeholder={field.label} type="password" name={field.name} onChange={formChange} 
				value={form[field.name]} autoComplete="off" />;
		case "checkbox":
			let checkboxLabel = field.label;
			if (field.link) checkboxLabel = <a href={field.link} target="_blank" rel="noopener noreferrer">{checkboxLabel}</a>;
			return (
				<div className={styles['form-check-wrapper']}>
					<input className={styles['form-check']} type="checkbox" id={field.name} name={field.name} onChange={formChange} />{checkboxLabel}
				</div>
			)
		case "oauth2":
			return (
				<div className={styles['form-oauth']}>
					{loginOAuth2({...field})}
				</div>
			);
		default:
			return null;
		}
	}


	const buildList = () => {
		return flow.data.fields.map((field, idx) => {
			return (
				<div
					className={styles['item']}
					key={idx}
				>
					{buildItem(field)}
				</div>
			)
		})
	}

	const buildBackBtn = () => {
		if (!flow.data.initial) {
			return (
				<button
					className={common['btn-back']}
					onClick={(e) => submitTrigger({type: "reset"})}
					type="button"
				>前へ戻る</button>
			)
		}
		else {
			return null
		}
	}


	if (user === null || flow === null || session === null || form === null) {
		return <Loading />
	}

	if ((user) && (user.data)) {
		return history.location.search ? <Redirect to={`${history.location.search.split('?return_to=')[1]}`}/> : <Redirect to="/"/>
	}

	return (
		<>
			<div
				className={`${common['main-wrapper']}`}
			>
				<h2 className={common['title']}>ログイン</h2>
				<form
					className={styles['form']}
					onSubmit={(e) => submitTrigger({type: "submit"}, e)}
				>
					{buildList()}
					<div className={common['btn-warapper']}>
						{buildBackBtn()}
						<button className={common['btn-submit']} type="submit">次へ</button>
					</div>
				</form>
				<ToastContainer />
			</div>
		</>
	);
}

export default Login;
