import React, { useState } from 'react';
import { rest } from "@karpeleslab/klbfw";
import { Link } from "react-router-dom";


// style
import common from "scss/common.module.scss";
import styles from 'scss/forgotten.module.scss';

//img
import mv from "resources/img/mainimage.jpg";

// component
import Title from "components/title/title";
import Helmet from 'components/helmet/helmet';
import Loading from "components/loading/loading";

const ForgottenPassword = () => {
	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState({
		email: null
	});
	const [sent, setSent] = useState(false);

	const formChange = (event) => {
		const array = {}
		array[event.target.getAttribute('name')] = event.target.value;

		setForm({
			...form,
			...array
		});

	};

	const emailSubmit = (event) => {
		event.preventDefault();

		setLoading(true);

		// local check
		// setTimeout(() => {
		// 	setLoading(false);
		// 	setSent(true);
		// }, 3000)		

		rest("User:forgot_password", "POST", {login: form.email})
			.then(
				(data) => {
					if (data.result === "success") {
						setLoading(false);
						setSent(true);
					}
				}
			)
	}

	const buildContent = () => {
		if (sent === false && loading === false) {
			return (
				<>
					<p>パスワードをお忘れの場合、メールアドレスを入力し送信ボタンを押してください。<br/>パスワードをリセットする手順を記載したメールが送信されます。</p>
					<form onSubmit={(e) => {emailSubmit(e)}}>
						<div
							className={common['form-item']}
						>
							<input className={common['input']} placeholder="メールアドレス*" type="email" name="email" onChange={formChange} required/>
						</div>

						<div className={common['btn-warapper']}>
							<button className={common['btn-submit']} type="submit" disabled={!form.email}>送信</button>
						</div>
					</form>
				</>
			)
		}
		else if (sent === false && loading === true) {
			return <Loading />
		}
		else {
			return (
				<>
					<p style={{textAlign:"center"}} >メールアドレスにメールが送信されました。</p>

					<div className={common['btn-warapper']}>
						<Link
							to="/"
						>トップページに戻る</Link>
					</div>
				</>
			)
		}
	}

	return (
		<>
			<Helmet>
				<title>パスワード再設定 | ラップオバケ OFFICIAL WEB SITE</title>
				<meta property="og:title" content="パスワード再設定 | ラップオバケ OFFICIAL WEB SITE" />
				<meta name="twitter:title" content="パスワード再設定 | ラップオバケ OFFICIAL WEB SITE" />
			</Helmet>
			<div
				className={common['mv']}
				style={{
					backgroundImage: `url(${mv})`
				}}
			>
			</div>
			<div
				className={`${common['main-wrapper']}`}
			>
				<section
					className={common['common-wrapper']}
				>
					<Title
						title="パスワード再設定"
						type="long"
					/>
					<div
						className={styles['forgotten-wrapper']}
					>
						{buildContent()}
					</div>
				</section>
			</div>
		</>
	);
}

export default ForgottenPassword;
