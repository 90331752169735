import React　from 'react';
import classNames from 'classnames';

// style
import common from "scss/common.module.scss";

const Title = (prop) => {
	const {title, type} = prop;

	return (
		<h2 className={classNames(`${common['common-title']}`,
			{
				[common['common-title__long']]: type === "long"
			}
		)}>{title}</h2>
	);
}

export default Title;
