import React, { useEffect, useState } from 'react';
import { useRest, useRestRefresh } from '@karpeleslab/react-klbfw-hooks';
import { ToastContainer, toast } from 'react-toastify';
import moment from "moment";
import { rest } from '@karpeleslab/klbfw';
import DatePicker from "react-datepicker";
 
// style
import common from "scss/common.module.scss";
import styles from 'scss/account.module.scss';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";

// component
import Helmet from 'components/helmet/helmet';
import OverLay from 'components/loading/overlay';

const Profile = () => {
	const [user] = useRest("User:get");
	const [profile, setProfile] = useState({
		userName: '',
		birth: '',
		gender: ''
	});
	const [loading, setLoading] = useState(false);
	const userRefresh = useRestRefresh("User:get");

	useEffect(() => {
		setProfile({
			userName: user.data.Profile.Display_Name,
			birth: user.data.Profile.Birthdate === '0000-00-00' ? moment().format('YYYY-MM-DD') : moment(user.data.Profile.Birthdate).format('YYYY-MM-DD'),
			gender: user.data.Profile.Gender === null ? '' : user.data.Profile.Gender,
		})
	}, [user]);

	const formChange = (event) => {
		const array = {}
		array[event.target.getAttribute('name')] = event.target.value;

		setProfile({
			...profile,
			...array
		});
	};

	const buildOverLay = () => {
		if (!loading) {
			return null
		} else {
			return <OverLay />
		}
	}

	const submitForm = (event) => {
		event.preventDefault();

		setLoading(true);

		const data = {
			Gender: profile.gender === '' ? null : profile.gender,
			Display_Name: profile.userName,
			Birthdate: profile.birth === '' ? moment().format('YYYY-MM-DD') : moment(profile.birth).format('YYYY-MM-DD')
		};

		rest("User/@/Profile", "PATCH", data)
			.then(() => {
				setLoading(false);
				toast.success(<div>更新が完了しました。<br />ページを更新します。</div>, {
					position: "top-center",
					autoClose: 1500,
					onClose: () => {userRefresh()}
				});
			})
			.catch(err => {
				setLoading(false);
				toast.error(err.message, {
					position: "top-center",
					autoClose: 3000,
				});
			});
	}

	const birthSubmit = (data) => {
		setProfile({
			...profile,
			birth: data === '' || data === void 0 ? moment().format('YYYY-MM-DD') : moment(data).format('YYYY-MM-DD')
		});
	}

	const buildBirth = () => {
		if (profile.birth.length !== 0) {
			return (<>
				<DatePicker
					className={common['input']}
					onChange={(data) => {birthSubmit(data)}}
					selected={new Date(moment(profile.birth).format('YYYY-MM-DD'))}
					dateFormat="yyyy-MM-dd"
					locale='ja'
				/>
			</>)
		}
		else {
			return null
		}
	}

	return (
		<>
			{buildOverLay()}
			<Helmet>
				<title>ユーザー情報 | ラップオバケ OFFICIAL WEB SITE</title>
				<meta property="og:title" content="ユーザー情報 | ラップオバケ OFFICIAL WEB SITE" />
				<meta name="twitter:title" content="ユーザー情報 | ラップオバケ OFFICIAL WEB SITE" />
			</Helmet>

			<h1
				className={styles['account-body-title']}
			>ユーザー情報</h1>
			<p
				className={styles['account-body-text']}
			>ユーザー情報を変更する場合は入力を行い更新をクリックしてください。</p>

			<form onSubmit={(e) => {submitForm(e)}}>
				<div
					className={common['form-item']}
				>
					<dl className={common['form-item-group']}>
						<dt className={common['form-item-group-label']}>ユーザーネーム</dt>
						<dd className={common['form-item-group-field']}>
							<input
								className={common['input']}
								placeholder="ユーザーネーム"
								type="text"
								name="userName"
								value={profile.userName}
								onChange={(e) => {formChange(e)}}
							/>
						</dd>
					</dl>
				</div>{/* formItem */}

				<div
					className={common['form-item']}
				>
					<dl className={common['form-item-group']}>
						<dt className={common['form-item-group-label']}>生年月日</dt>
						<dd className={common['form-item-group-field']}>
							{buildBirth()}
						</dd>
					</dl>
				</div>{/* formItem */}

				<div
					className={common['form-item']}
				>
					<dl className={common['form-item-group']}>
						<dt className={common['form-item-group-label']}>性別</dt>
						<dd className={common['form-item-group-field']}>
							<select
								className={`${common['input']} ${common['input-select']}`}
								name="gender"
								onChange={(e) => {formChange(e)}}
								value={profile.gender}
							>
								<option
									value=""
								>選択してください</option>
								<option
									value='M'
								>男性</option>
								<option
									value='F'
								>女性</option>
							</select>
						</dd>
					</dl>
				</div>{/* formItem */}
				<div className={`${common['btn-warapper']} ${common['btn-warapper__right']}`}>
					<button className={common['btn-submit']} type="submit" disabled={loading}>更新</button>
				</div>
			</form>
			<ToastContainer />
		</>
	);
}

export default Profile;