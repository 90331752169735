import React from 'react';
import { CardElement, useStripe, useElements} from "@stripe/react-stripe-js";
import { useTranslation } from 'react-i18next';

// style
import common from "scss/common.module.scss";

const Stripe = (props) => {
	const { submitForm, stripeError } = props;
	const stripe = useStripe();
	const elements = useElements();
	const { t } = useTranslation();
	
	const cardOptions = { 
		iconStyle: "solid",
		hidePostalCode: true,
		style: {
			base: {
				iconColor: '#737475',
				color: '#737475',
				fontWeight: 500,
				fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
				fontSize: '16px',
				fontSmoothing: 'antialiased',
				':-webkit-autofill': {color: '#737475'},
				'::placeholder': {color: '#737475'},
			},
			invalid: {
				iconColor: '#ff0000',
				color: '#ff0000',
			},
		},
	};


	const submitHandler = () => {
		const cardElement = elements.getElement(CardElement);

		stripe.createToken(cardElement)
			.then((token) => {
				if (token.token === void 0) {
					stripeError(token.error.message)
				} else {
					submitForm(token)
				}
			})
	}

	return (
		<>
			<div className={common['wrapper']}>
				<CardElement options={cardOptions}/>
			</div>
			<div className={common['btn-warapper']}>
				<button className={common['btn-submit']} type="button" onClick={submitHandler}>{t('order_card_btn')}</button>
			</div>
		</>
	)
}

export default Stripe;
