import React, { useEffect, useState } from 'react';
import { useRest, useRestRefresh } from '@karpeleslab/react-klbfw-hooks';
import { useLocation, useParams, Link } from "react-router-dom";
import moment from "moment";
import { useTranslation } from 'react-i18next';

// style
import styles from 'scss/account.module.scss';

// component
import Helmet from 'components/helmet/helmet';
import Loading from "components/loading/loading";
import Pager from "components/paging/paging";

const OrderHistory = () => {

	const { t } = useTranslation();
	const { pageNo } = useParams();
	const location = useLocation();
	const [page, setPage] = useState( pageNo === void 0 ? 1 : pageNo);
	const [order] = useRest("Order", { 
		page_no: page,
		results_per_page: 10,
		Status: {'$in': ['completed', 'pending-initiated']}
	}, true);

	const listRefresh = useRestRefresh("Order", { 
		page_no: page,
		results_per_page: 10,
		Status: {'$in': ['completed', 'pending-initiated']}
	});

	useEffect(() => {
		setPage(pageNo === void 0 ? 1 : pageNo)
	}, [location.pathname, pageNo])


	useEffect(() => {
		if (order === null) {
			listRefresh()
		}
	}, [page, order, listRefresh])

	const buildPdfLink = (item) => {

		if (item.Invoice_Url === void 0) {
			return "-"
		} else {
			return <a href={item.Invoice_Url}>PDF</a>;
		}

	}


	const buildList = () => {
		return order.data.map((item, idx) => {
			const invoiceDate = moment(parseInt(item.Created?.unixms)).format("YYYY-MM-DD HH:mm:ss");
			const invoiceNum = item.Invoice_Number;
			return (
				<tr key={idx}>
					<td
						className={styles['account-table-center']}
					>{invoiceNum !== null ? invoiceNum : "-"}</td>
					<td>{invoiceDate !== "Invalid date" ? invoiceDate : "-"}</td>
					<td>{t(`order-status-${item.Status}`)}</td>
					<td>{item.Total_Vat.display}</td>
					<td>{buildPdfLink(item)}</td>
					<td><Link to={`/order/${item.Order__}?type=history`}>詳細</Link></td>
				</tr>
			)
		})

	}

	const buildContent = () => {

		if (order === null) {
			return (
				<tr>
					<td colSpan="6"><Loading /></td>
				</tr>
			)
		} else {
			return buildList();
		}

	}

	return (
		<>
			<Helmet>
				<title>購入履歴 | ラップオバケ OFFICIAL WEB SITE</title>
				<meta property="og:title" content="購入履歴 | ラップオバケ OFFICIAL WEB SITE" />
				<meta name="twitter:title" content="購入履歴 | ラップオバケ OFFICIAL WEB SITE" />
			</Helmet>

			<h1
				className={styles['account-body-title']}
			>購入履歴</h1>
			<p
				className={styles['account-body-text']}
			>購入の履歴が確認できます。</p>
			<div
				className={styles['account-table-wrapper']}
			>
				<table
					className={styles['account-table']}
				>
					<thead>
						<tr>
							<th>送り状番号</th>
							<th>注文日</th>
							<th>ステータス</th>
							<th>価格</th>
							<th>PDF</th>
							<th>詳細</th>
						</tr>
					</thead>
					<tbody>
						{buildContent()}
					</tbody>
				</table>
			</div>
			<Pager list={order} page={page} path='account/order-history' />
		</>
	);
}

export default OrderHistory;
