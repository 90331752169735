import React from 'react';
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import { useParams, Redirect } from "react-router-dom";
import ReactHelmet from "react-helmet";


// component
import Loading from "components/loading/loading"

// style
import common from "scss/common.module.scss";

const Legal = () => {
	const { slug } = useParams();
	const [ article ] = useRest("Content/Cms/@legal:loadSlug", {slug: slug});
	
	const buildArticle = () => {
		
		if (article === null) {
			return <Loading />
		}
		else if (article.slug_redirect) {
			return <Redirect to={`/legal/${article.slug_redirect}`} />
		}
		else {
			return (
				<>
					<ReactHelmet>
						<title>{article.data.content_cms_entry_data.Title} | ラップオバケ OFFICIAL WEB SITE</title>
					</ReactHelmet>
					<h2 className={common['title']}>{article.data.content_cms_entry_data.Title}</h2>
					<div
						className="cms-content"
						dangerouslySetInnerHTML={{__html : article.data.content_cms_entry_data.Contents}} />
				</>
			)
		}
	}





	return (
		<div
			className={`${common['main-wrapper']}`}
		>
			{buildArticle()}
		</div>
	)
}

export default Legal;