import React from 'react';
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import moment from "moment";
import { Link } from "react-router-dom";
import classNames from 'classnames';

// style
import common from "scss/common.module.scss";
import styles from "scss/index.module.scss";

//img
import lineImg from "resources/img/lineat.jpg";

// component
import Title from "components/title/title";
import Loading from "components/loading/loading";

const Live = () => {

	const [live] = useRest("Planner/@planner/Schedule", { Visible: "Y", sort: { Date: "DESC"}});

	const getDayLocale = (date) => {
		return moment(date).format('YYYY.MM.DD');
	}

	const oneWeekAgo = moment().subtract(7, "d");

	const buildLiveList = () => {
		if (live === null) {
			return <Loading />
		}
		else {
			return (
				live.data.map(item => {

					if (item.Planner_Event__) {
						return null
					} else {
						return (
							<dl
								key={item.Planner_Schedule__ }
								className={classNames(styles['liveList'], {
									[styles['liveList-new']]: moment(item.Date.iso).isAfter(oneWeekAgo)
								})}
							>
								<dt>
									{ getDayLocale(item.Date.iso) }
								</dt>
								<dd>
									<Link
										to={`/live/${item.Content_Cms_Entry_Data.Slug}`}
									>
										{item.Content_Cms_Entry_Data.Title}
									</Link>
								</dd>
							</dl>
						)
					}
				})
			)
		}
	}

	return (
		<>
			<section
				className={common['common-wrapper']}
			>
				<Title
					title="LIVE"
				/>
				{buildLiveList()}
				<div
					className={styles['lineAt']}
				>
					<a href="https://line.me/R/ti/p/%40srx2166f">
						<img src={lineImg} alt="ラップオバケのライン@に登録してね" />
					</a>
				</div>
			</section>
		</>
	);
}

export default Live;
