import React from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import _ from 'lodash';

// style
import styles from 'scss/profile.module.scss';

const ProfileCard = ({memberData, variation}) => {

	const twitterName = _.filter(memberData.Links, {Type: "twitter"})[0].Link.replace("https://twitter.com/", "");

	return (
		<>
			<div
				className={styles['profile-wrapper']}
			>
				<div
					className={styles['profile-info']}
				>
					{ memberData.Image_Drive_Item && <img src={memberData.Image_Drive_Item.Media_Image.Variation[variation]} alt={memberData.Name} /> }
					<p className={styles["profile-name"]}>{memberData.Name}</p>
					<p>{memberData.Bio}</p>
				</div>
				<div
					className={styles['profile-twitter']}
				>
					<TwitterTimelineEmbed
						sourceType="timeline"
						screenName={twitterName}
						options={{height:750 }}
						lang="ja"
					/>
				</div>
        
			</div>
		</>
	);
}

export default ProfileCard;
