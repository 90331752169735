import React from 'react';
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import { getSettings } from "@karpeleslab/klbfw";
import Slider from "react-slick";

// style
import common from "scss/common.module.scss";
import styles from "scss/index.module.scss";

//img
import mv from "resources/img/mainimage_210726.jpg";

// component

// slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const MainVisual = () => {
	const MAIN_IMG_VARIATION = 'format=jpeg&strip&scale_crop=2000x1000';
	const COVER_IMG_VARIARION = "strip&format=jpeg&scale_crop=1920x1080"
	const imgVariation = [
		MAIN_IMG_VARIATION,
	];

	const [mainVisual] = useRest("Content/Cms/@news:search", { sort: "published:desc", results_per_page: 1, query: {
		tag: "メインビジュアル"
	}, "image_variation": imgVariation });

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		autoplay: true,
		autoplaySpeed: 5000,
		slidesToShow: 1,
		slidesToScroll: 1
	};

	return (
		<>
			{mainVisual !== null && <div>
				<div
					className={styles['mv']}
				>
					<Slider {...settings}>
						<img src={getSettings().cover.Drive_Item__ !== null ? getSettings().cover.Variation[COVER_IMG_VARIARION] : mv} alt="RAPOBAKE" />
						{getSettings().cover1 !== void 0 && getSettings().cover1.Drive_Item__ !== null && <img src={getSettings().cover1.Variation[COVER_IMG_VARIARION]} alt="RAPOBAKE" />}
						{getSettings().cover3 !== void 0 && getSettings().cover3.Drive_Item__ !== null && <img src={getSettings().cover3.Variation[COVER_IMG_VARIARION]} alt="RAPOBAKE" />}
					</Slider>
				</div>
				{mainVisual.data.data.length !== 0 && <div className={`${common['common-wrapper']} ${styles["mv-info"]}`} dangerouslySetInnerHTML={{__html: mainVisual.data.data[0].Contents}}></div>}
			</div>
			}
		</>
	);
}

export default MainVisual;
