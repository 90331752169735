import React, { useState } from "react";
import { rest, flushGet } from "@karpeleslab/klbfw";
import { useRest, useRestRefresh } from '@karpeleslab/react-klbfw-hooks';
import { Link } from "react-router-dom";
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';

// style 
import styles from 'scss/header.module.scss'; 

// img
import logo from 'resources/img/img_logo.svg';

const Header = () => {
	const userRefresh = useRestRefresh("User:get");
	const [ user ] = useRest("User:get");
	const [ cart ] = useRest("Catalog/Cart/@");
	const [ menu, setMenu ] = useState(false);
	const [ showAccount, setShowAccount ] = useState(false);

	const [listMenu] = useState([
		{ name: "HOME", path: "/", type: "internal" },
		{ name: "PROFILE", path: "/profile", type: "internal" },
		{ name: "LIVE", path: "/live", type: "internal" },
		//{ name: "GOODS", path: "/goods", type: "internal" },
		{ name: "Twitter", path: "https://twitter.com/RAPOBAKE", type: "External" },
		{ name: "Instagram", path: "https://www.instagram.com/rap_obake/", type: "External" },
		{ name: "CONTACT", path: "/contact", type: "internal" }
	])

	const logout = () => {
		rest("User/@:logout", "POST")
			.then(
				() => {
					flushGet();
					userRefresh();
				}
			);
	}

	const handleAccount = () => {
		if (!showAccount) {
			setShowAccount(true);
		} else {
			setShowAccount(false);
		}
	}

	const buildLogout = () => {

		if (user === null) return false;

		if (!user.data) {
			return (
				<Link
					to="/login"
					onClick={() => { menuActive() }}
				>ログイン</Link>
			)
		}
		else {
			return (
				<div
					className={styles['header-btn-account-wrapper']}
				>
					<button
						className={styles['header-btn-account']}
						onClick={() => {
							handleAccount()
						}}
					>アカウント
						{!showAccount && <FontAwesomeIcon icon={faAngleDown} />}
						{showAccount && <FontAwesomeIcon icon={faAngleUp} />}
					</button>
					<div
						className={classNames(styles['header-account'], {
							[styles['header-account-active']]: showAccount
						})}
					>
						<ul>
							<li>			
								<Link
									to="/account"
									onClick={() => {
										menuActive()
										setShowAccount(false);
									}}
								>アカウント</Link>
							</li>
							<li>			
								<Link
									to="/account/order-history"
									onClick={() => {
										menuActive()
										setShowAccount(false);
									}}
								>購入履歴</Link>
							</li>
							<li>
								<button
									onClick={() => {
										logout()
										menuActive()
										setShowAccount(false);
									}}
								>ログアウト</button>
							</li>
						</ul>
					</div>
				</div>
			)
		}
	}

	const menuActive = () => {
		if (!menu) {
			setMenu(true);
		}
		else {
			setMenu(false);
		}
	}

	const buildLink = () => {

		if (cart === null) return false;

		return (
			<>
				<div
					className={classNames(`${styles['header-btn']}`,
						{
							[styles['active']]: menu === true
						}
					)}
				>
					
					{buildMenuList()}
					<Link
						to="/cart"
						onClick={() => { menuActive() }}
						className={`${styles['header-btn-cart']}`}
					>
						カート<span>{cart.data.products.length}</span>
					</Link>
					{buildLogout()}
				</div>
				<div
					className={classNames(`${styles['spMenu']}`,
						{
							[styles['active']]: menu === true
						}
					)}
					onClick={() => { menuActive() }}
				>
					<span></span>
					<span></span>
					<span></span>
				</div>
			</>
		)
	}

	const buildMenuItem = (data) => {
		const type = data.type;
		const name = data.name;
		const path = data.path;


		if (type === "internal") {
			return (
				<Link
					onClick={() => { menuActive() }}
					to={path}
				>{name}</Link>
			)
		} else {
			return (
				<a
					href={path}
					target='_newsupportwindow'
					onClick={() => { menuActive() }}
				>
					{name}
				</a>
			)
		}
		
	}

	const buildMenuList = () => {
		return (
			<ul
				className={styles['header-list']}
			>
				{
					listMenu.map((data, idx) => {
						return (
							<li
								key={idx}
							>
								{buildMenuItem(data)}
							</li>
						)
					})
				}
			</ul>
		)
	}

	return (
		<header
			className={styles['header']}
		>
			<h1>
				<Link
					to="/"
				>
					<img src={logo} alt="" />
				</Link>
			</h1>
			{buildMenuList()}
			{buildLink()}
		</header>
	);
};

export default Header;
