import React, { useEffect, useState } from 'react';
import { useRest, useRestRefresh } from '@karpeleslab/react-klbfw-hooks';
import { ToastContainer, toast } from 'react-toastify';
import { rest } from '@karpeleslab/klbfw';

// style
import common from "scss/common.module.scss";
import styles from 'scss/account.module.scss';
import 'react-toastify/dist/ReactToastify.css';

// component
import Helmet from 'components/helmet/helmet';
import OverLay from 'components/loading/overlay';
import Password from 'components/password/password';

const Email = () => {
	const [user] = useRest("User:get");
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);
	const [passwordOverlay, setPasswordOverlay] = useState(false);
	const [password, setPassword] = useState('');
	const userRefresh = useRestRefresh("User:get");

	useEffect(() => {
		setEmail(user.data.Email);
	}, [user]);

	const formChange = (event) => {
		setEmail(event.target.value);
	};

	const emailSubmit = (event) => {
		event.preventDefault();
		setPasswordOverlay(true);
	}

	const buildOverLay = () => {
		if (!loading) {
			return null
		} else {
			return <OverLay />
		}
	}

	const submitForm = (event) => {
		event.preventDefault();

		setLoading(true);

		const data = {
			email: email,
			current_password: password
		};
		
		rest("User/@:setEmail", "POST", data)
			.then(data => {
				setPasswordOverlay(false);
				setLoading(false);
				toast.success(<div>更新が完了しました。<br />ページを更新します。</div>, {
					position: "top-center",
					autoClose: 1500,
					onClose: () => {userRefresh()}
				});
			})
			.catch(err => {
				setLoading(false);
				toast.error(err.message, {
					position: "top-center",
					autoClose: 3000,
				});
			});

	}


	const buildPassword = () => {
		if (!passwordOverlay) {
			return null
		}
		else {
			return <Password
				setPasswordOverlay={setPasswordOverlay}
				setLoading={setLoading}
				submitForm={submitForm}
				password={password}
				setPassword={setPassword}
			/>
		}
	}

	return (
		<>
			{buildOverLay()}
			{buildPassword()}
			<Helmet>
				<title>メールアドレス | ラップオバケ OFFICIAL WEB SITE</title>
				<meta property="og:title" content="メールアドレス | ラップオバケ OFFICIAL WEB SITE" />
				<meta name="twitter:title" content="メールアドレス | ラップオバケ OFFICIAL WEB SITE" />
			</Helmet>

			<h1
				className={styles['account-body-title']}
			>メールアドレス</h1>
			<p
				className={styles['account-body-text']}
			>メールアドレスを変更する場合は入力を行い更新をクリックしてください。</p>

			<form onSubmit={(e) => {emailSubmit(e)}}>
				<div
					className={common['form-item']}
				>
					<dl className={common['form-item-group']}>
						<dt className={common['form-item-group-label']}>メールアドレス</dt>
						<dd className={common['form-item-group-field']}>
							<input
								className={common['input']}
								placeholder="email"
								type="email"
								name="email"
								value={email}
								onChange={(e) => {formChange(e)}}
							/>
						</dd>
					</dl>
				</div>
				<div className={`${common['btn-warapper']} ${common['btn-warapper__right']}`}>
					<button className={common['btn-submit']} type="submit" disabled={email === user.data.Email || loading}>更新</button>
				</div>
			</form>
			<ToastContainer />
		</>
	);
}

export default Email;