import React from 'react';
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';


// style
import styles from "scss/checkout.module.scss";
import common from "scss/common.module.scss";


const Countries = (props) => {
	const { valChange, lang, rowItem, item, buildRequired } = props;
	const [countries] = useRest('Country');
	const { t } = useTranslation();

	const buildOption = () => {

		if (countries === null) return false;

		return countries.data.map( (item, idx) => {
			return (
				<option
					key={idx}
					value={item.Country__}
				>{item.Name}</option>
			)
		})

	}

	return (
		<dl
			className={classNames(`${styles['list-item']}`,
				{
					[styles['list-item-bisected']]: item.length === 2
				}
			)}>
			<dt>{t(`${rowItem.name}_lbl`)}{buildRequired(rowItem.required)}</dt>
			<dd>
				<select
					className={`${common['input']} ${common['input-select']}`}
					name={rowItem.name}
					onChange={valChange}
					value={lang}
				>
					{buildOption()}
				</select>
			</dd>
		</dl>
	);
}

export default Countries;
