import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';

// style
import styles from "scss/password.module.scss";
import common from "scss/common.module.scss";

const OverLay = (prop) => {

	const { setPasswordOverlay, submitForm, setPassword, password } = prop;
	const passwordInput = useRef(null);

	useEffect(() => {
		passwordInput.current.focus();
	}, [passwordInput])

	return (
		<>
			<div className={styles['password-wrapper']}>
				<span
					className={styles['password-close']}
					onClick={() => setPasswordOverlay(false)}
				>
					<FontAwesomeIcon icon={faWindowClose} />
				</span>
				<h2
					className={styles['password-title']}
				>パスワードが必要です。</h2>
				<p
					className={styles['password-text']}
				>セキュリティ上の理由から、要求された変更を行うにはパスワードが必要です。</p>
				<form onSubmit={(e) => {submitForm(e)}}>
					<div
						className={common['form-item']}
					>
						<input
							className={common['input']}
							ref={passwordInput}
							placeholder="パスワードを入力してください。"
							type="password"
							name="password"
							onChange={(e) => { setPassword(e.target.value) }}
						/>
					</div>

					<div className={`${common['btn-warapper']} ${common['btn-warapper__right']}`}>
						<button
							className={common['btn-submit']}
							type="submit"
							disabled={password.length === 0}
						>保存</button>
					</div>
				</form>
			</div>
			<div
				className={styles['password-overlay']}
				onClick={() => {setPasswordOverlay(false)}}
			></div>
		</>
	);
};

export default OverLay;

