import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// page
import Layout from 'components/layout/Layout';
import Index from 'components/pages/index/index';
import Cart from 'components/pages/cart/cart';
import Checkout from 'components/pages/checkout/checkout';
import Login from 'components/pages/login/login';
import Order from 'components/pages/order/order';
import Thanks from 'components/pages/thanks/thanks';
import Legal from 'components/pages/legal/legal';
import RedirectPage from 'components/pages/redirect/redirect';
import Goods from 'components/pages/goods/goods';
import Profile from 'components/pages/profile/profile';
import Contact from 'components/pages/contact/contact';
import Page from 'components/pages/page/page';
import Live from 'components/pages/live/live';
import LiveDetail from 'components/pages/live/detail';
import ForgottenPassword from 'components/pages/forgottenPassword/forgottenPassword';
import RecoverPassword from 'components/pages/recoverPassword/recoverPassword';
import Account from "components/pages/account/account";

// mail
import AccountConfirmMail from "components/mails/Account/Confirm/Mail";
import BillingFailedMail from "components/mails/Order/Billing/Failed/Mail";
import BillingInvalidateMail from "components/mails/Order/Billing/Invalidate/Mail";
import NewOrderMail from "components/mails/Order/Created/Mail";
import BillPdf from "components/mails/Order/Billing/Pdf/Pdf";
import BankTransfer from "components/mails/Order/Transfer/Mail";
import Shipping from "components/mails/Order/Shipping/Mail";
import ForgottenPasswordMail from "components/mails/Password/Forgotten/Mail";
import RecoverPasswordMail from "components/mails/Password/Recover/Mail";


const Mail = () => {
	return <Switch>
		<Route exact path="/mail/account_confirm.mail">
			<AccountConfirmMail/>
		</Route>
		<Route exact path="/mail/order/billing_failed.mail">
			<BillingFailedMail/>
		</Route>
		<Route exact path="/mail/order/billing_method_invalidate.mail">
			<BillingInvalidateMail/>
		</Route>
		<Route exact path="/mail/order/order.mail">
			<NewOrderMail/>
		</Route>
		<Route exact path="/mail/order/bill.pdf">
			<BillPdf/>
		</Route>
		<Route exact path="/mail/payment_aozoranet.mail">
			<BankTransfer/>
		</Route>
		<Route exact path="/mail/order/shipping.mail">
			<Shipping/>
		</Route>
		<Route exact path="/mail/mail_forgotten_pwd.mail">
			<ForgottenPasswordMail/>
		</Route>
		<Route exact path="/mail/mail_pwd_recovered.mail">
			<RecoverPasswordMail/>
		</Route>
	</Switch>
}


const Pages = () => {
	return <Layout>
		<Switch>
			<Route exact path="/">
				<Index/>
			</Route>
			<Route exact path="/goods">
				<Goods/>
			</Route>
			<Route exact path="/profile">
				<Profile />
			</Route>
			<Route exact path="/contact">
				<Contact />
			</Route>
			<Route exact path="/news/:slug">
				<Page type="news" />
			</Route>
			<Route exact path="/live">
				<Live />
			</Route>
			<Route exact path="/live-:tag">
				<Live />
			</Route>
			<Route exact path="/live/:slug">
				<LiveDetail />
			</Route>
			<Route exact path="/cart">
				<Cart/>
			</Route>
			<Route exact path="/checkout">
				<Checkout/>
			</Route>
			<Route exact path="/login">
				<Login/>
			</Route>
			<Route exact path="/order/:order">
				<Order/>
			</Route>
			<Route exact path="/thankyou">
				<Thanks/>
			</Route>
			<Route exact path="/legal/:slug">
				<Legal/>
			</Route>
			<Route exact path="/forget-password">
				<ForgottenPassword/>
			</Route>
			<Route exact path="/recover-password/:login">
				<RecoverPassword/>
			</Route>
			<Route exact path="/account">
				<Account
					type="account"
				/>
			</Route>
			<Route exact path="/account/profile">
				<Account
					type="account-profile"
				/>
			</Route>
			<Route exact path="/account/password">
				<Account
					type="account-password"
				/>
			</Route>
			<Route exact path="/account/order-history">
				<Account
					type="account-history"
				/>
			</Route>
			<Route exact path="/account/order-history/page-:pageNo">
				<Account
					type="account-history"
				/>
			</Route>
			<Route exact path="/redirect">
				<RedirectPage/>
			</Route>
			<Route>
				<Redirect to=""/>
			</Route>
		</Switch>
	</Layout>
}

const Core = () => {
	return (
		<Switch>
			<Route path="/mail">
				<Mail/>
			</Route>
			<Route>
				<Pages/>
			</Route>
		</Switch>
	);
}

const Routing = () => {
	return <Core/>
}

export default Routing;
