import React from 'react';
import { useTranslation } from 'react-i18next';

const Html = () => {
	const { t } = useTranslation();
	return (
		<div id="mail-html">
			<div style={{
				textAlign:'center',
				marginLeft: 'auto',
				marginRight: 'auto',
				marginBottom: '20px'
			}}>
				<div style={{
					margin: 'auto',
					textAlign:'Left',
				}}>
					<p>{ t('mail_forgotten_completed_text_01') }</p>
					<p>{ t('mail_forgotten_completed_text_02') }</p>
					<p><a href="https://www.rapobake.com/login">https://www.rapobake.com/login</a></p>
					<p>{ t('mail_name') }</p>
				</div>
			</div>
		</div>
	);
};

export default Html;