import React from 'react';
import { useTranslation } from 'react-i18next';

// style
import styles from "scss/cart.module.scss";
import common from "scss/common.module.scss";

// component
import Loading from "components/loading/loading";

// icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';

const Cart = (props) => {

	const {cart, removeItem} = props;
	const { t } = useTranslation();

	const buildRremove = (item) => {
		if (removeItem !== null) {
			return (
				<div>
					<dt>{t('cart_remove')}</dt>
					<dd>
						<span onClick={() => removeItem(item.key, item.data["Basic.Name"])}>
							<FontAwesomeIcon icon={faWindowClose} />
						</span>
					</dd>
				</div>
			)
		}
		else {
			return null;
		}
	}

	const buildItem = () => {
		return cart.data.products.map((item, idx) => {
			return (
				<dl key={idx}>
					<div>
						<dt>{t('cart_name')}</dt>
						<dd>{item.data['Basic.Name']}</dd>
					</div>
					<div>
						<dt>{t('cart_quantity')}</dt>
						<dd>{item.quantity}</dd>
					</div>
					<div>
						<dt>{t('cart_price')}</dt>
						<dd>{item.price.tax.display}</dd>
					</div>
					{buildRremove(item)}
				</dl>
			)
		})
	}

	const buildAllPrice = () => {
		return (
			<dl className={styles['cart-total']}>
				<div>
					<dt>{t('cart_subtotal')}</dt>
					<dd>{cart.data.total.display}</dd>
				</div>
				<div>
					<dt>{t('cart_tax')}</dt>
					<dd>{cart.data.total_vat_only.display}</dd>
				</div>
				<div>
					<dt>{t('cart_total')}</dt>
					<dd>{cart.data.total_vat.display}</dd>
				</div>
			</dl>
		)
	}


	const buildCart = () => {
		
		if (cart === null) {
			return <Loading />
		}
		else if (cart.data.products.length === 0) {
			return (
				<div
					className={styles['cart-nothing']}
				>
					{t('cart_no_item')}
				</div>
			)
		}
		else {
			return (
				<>
					<div className={styles['cart-item']}>
						{buildItem()}
					</div>
					{buildAllPrice()}
				</>
			)
		}
	}


	return (
		<div className={common['wrapper']}>
			{buildCart()}
		</div>
	);
}

export default Cart;